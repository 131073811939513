import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types'

const MarkInput = (props) => {
    const { mask, maskChar, placeholder, value, onChange, refElement } = props;
    return <InputMask 
                mask={mask}
                maskChar={maskChar}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={refElement}
                className="form-control form-control-md" />;
}

MarkInput.prototype = {
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
}
export default MarkInput;