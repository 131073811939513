import React from "react";
import PersonalData from "../Components/Organisms/PersonalData";
import CustomCard from "../Components/Templates/CustomCard";
import PropTypes from "prop-types";
import { Spinner, Row, Col } from "react-bootstrap";
const PersonalInfoView = (props) => {
    const { defaultData, origen, loading } = props;
    return (
        
        <CustomCard 
            text={"Completa la información solicitada"}
            content={
                <>
                    {
                        loading?
                            <Row className="justify-content-center">
                                <Col md={2} className="mb-3">
                                    <Spinner animation="grow" role="status" className="string-primary-color" style={{marginBottom: '5px'}} />
                                    <span className="visually-hidden">Cargando información, por favor espere...</span>
                                </Col>
                            </Row>
                        :null
                    }
                    <PersonalData defaultData={defaultData} origen={origen}/>
                </>
            }
        />
    );
}
PersonalInfoView.propTypes = {
    defaultData: PropTypes.object
}
export default PersonalInfoView;