import React, { useState, useEffect, useRef } from "react";
/** Boton provisional para simular el CATCHA */
import { Row, Col/*, Button*/ } from "react-bootstrap";
import Check from "../../src/Components/Atoms/ReactBootstrapCheck";
import FormGroup from "../../src/Components/Molecules/ReactBootstrapFormGroupInput";
// import FormGroupSelect from "../../Molecules/ReactBootstrapFormGroupSelect";
import ReactBootstrapButton from "../../src/Components/Atoms/ReactBootstrapButton";
import ReactBootstrapLoadingButton from "../../src/Components/Atoms/ReactBootstrapLoadingButton";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setValue } from "../../src/features/counter/personalDataSlice";
import { setValue as generalSetValue } from "../../src/features/counter/generalSlice";
import { setValue as laborAcademicSetValue } from "../../src/features/counter/laborAcademicSlice";
import { useNavigate } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { paths } from "../../src/Models/general.model";
import validator from "validator";
import CustomModal from "../../src/features/snippets/modal";
import Swal from "sweetalert2";
// import PhoneInput from "../../Atoms/PhoneInput";
import MarkInputGroup from "../../src/Components/Molecules/MarkInputGroup";
import ReactSelectGroup from "../../src/Components/Molecules/ReactSelectGroup";
import ReCAPTCHA from "react-google-recaptcha";
import FormGroupSelect from "../../src/Components/Molecules/ReactBootstrapFormGroupSelect";
import FormGroupDate from "../../src/Components/Molecules/ReactBootstrapFormGroupDate";

import { InputNumber } from 'primereact/inputnumber';

import { AxiosBackend_API, AxiosBackend_API_PB3 } from "../../src/config/Axios";

import { setValue as setValueLogin } from "../../src/features/counter/loginSlice";


const defaultValid = {

    key: '',
    text: '',
    valid: true
};
const PersonalInfoMadresView = (props) => {

    const { defaultData, origen } = props,
        { token } = useSelector((state) => state.login.value),
        domiciledObject = useSelector((state) => state.domiciled.value),
        personalDataObject = useSelector((state) => state.personalData.value),
        laborAcademicObject = useSelector((state) => state.laborAcademic.value),
        typeProduct = useSelector((state) => state.typeProduct.value),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        checkRef = useRef(),
        [resValid, setResValid] = useState(defaultValid),
        [showModal, setShowModal] = useState(false),
        [loading, setLoading] = useState(false),
        [montoASolicitar, setMontoASolicitar] = useState(''),
        [reCAPTCHAValid, setReCAPTCHAValid] = useState(false),
        [plazo, setPlazo] = useState(''),
        [subProductos, setSubProductos] = useState([]),
        [origenContactCenter, setOrigenContactCenter] = useState([]),
        [idOrigen, setIdOrigen] = useState(false),
        [idPersona, setIdPersona] = useState(0),
        [showOrigen, setShowOrigen] = useState(false),
        [selectedOficial, setSelectedOficial] = useState(0),
        phoneCellRef = useRef();

    // useEffect(() => {
    //     // const rValid = validation();
    //     // setResValid(rValid);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [personalDataObject]);

    function validCedula(cedula) {
        if (typeof cedula != "string") return false

        //cleanup
        cedula = cedula.replace(/-/g, "");

        // La cédula debe tener 11 dígitos
        if (cedula.length !== 11) return false

        // Validar serie
        if (parseInt(cedula.substring(0, 3)) !== 402 && parseInt(cedula.substring(0, 3)) > 121 && parseInt(cedula.substring(0, 3)) < 1) return false

        var suma = 0
        var verificador = 0

        for (var i = 0; i < cedula.length; i++) {
            let n = cedula.charAt(i);
            //No ejecutar el ultimo digito
            if (i === (cedula.length) - 1) break;

            // Los dígitos pares valen 2 y los impares 1
            let multiplicador = (parseInt(i) % 2) === 0 ? 1 : 2;

            // Se multiplica cada dígito por su paridad
            let digito = parseInt(n) * parseInt(multiplicador)

            // Si la multiplicación da de dos dígitos, se suman entre sí
            digito = digito > 9 ? [...digito.toString()].map(e => parseInt(e)).reduce((a, b) => a + b) : digito;

            // Se va haciendo la acumulación de esa suma
            suma = suma + digito
        }
        // Al final se obtiene el verificador con la siguiente fórmula
        verificador = (10 - (suma % 10)) % 10

        // Se comprueba el verificador
        return (verificador === parseInt(cedula.slice(-1)))
    }

    const saveRequest = async () => {
        const rValid = validation();
        setResValid(rValid);
        if (!rValid.valid) {
            return;
        }
        if (!reCAPTCHAValid) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Los datos del reCAPTCHA no son validos',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return false;
        }
        if (!checkRef.current.checked) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debes estar de acuerdo con las políticas de autorización de uso de información',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return;
        }

        setLoading(true);

        try {

            dispatch(setValue({ key: 'applying', value: true }));


            setLoading(false);


        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleChangePlazo = ({ target }) => {

        const newValue = target.value;

        if (newValue.length > 2) {
            newValue = newValue.slice(0, 2);
        }

        if (/^\d*$/.test(newValue)) {
            setPlazo(target.value)
            dispatch(setValue({ key: 'plazo', value: parseInt(target.value) }))
        }


    }

    const validation = () => {
        if (!validator.isAlpha(personalDataObject.firstName, ['es-ES'])) {
            return { key: 'firstName', text: 'Favor ingresar su primer nombre', valid: false };
        }
        if (!validator.isAlpha(personalDataObject.lastName, ['es-ES'])) {
            return { key: 'lastName', text: 'Favor ingresar su primer apellido', valid: false };
        }
        let identity = personalDataObject.id.replace("-", "");
        identity = identity.replace("-", "");
        identity = identity.replace("_", "");
        // if (!validator.isLength(identity, { min: 11, max: 11 })) {
        //     return { key: 'id', text: 'Favor ingresar un numero de cédula valida', valid: false };
        // }
        // if (!validator.isEmail(personalDataObject.email)) {
        //     return { key: 'email', text: 'Favor ingresar un correo electrónico valido', valid: false };
        // }
        /** check station */
        let serie = personalDataObject.phone.substr(0, 3);
        const serieArr = ['809', '829', '849'];
        if (!serieArr.includes(serie)) {
            return { key: 'phone', text: 'La serie del numero de celular no es valida', valid: false };
        }

        let cellPhone = personalDataObject.phone.replace("-", "");
        cellPhone = cellPhone.replace("-", "");
        cellPhone = cellPhone.replace("_", "");
        if (!validator.isLength(cellPhone, { min: 10, max: 10 })) {
            return { key: 'phone', text: 'Favor ingresar un numero de celular valido', valid: false };
        }
        if (!validator.isMobilePhone(cellPhone)) {
            return { key: 'phone', text: 'El numero de celular no es valido', valid: false };
        }

        return { key: 'success', text: 'Todos los campos fueron validados', valid: true };
    }
    const reCaptchaOnChange = (value) => {
        //console.log("value>>",value);

        if (value !== null && value !== undefined) {
            setReCAPTCHAValid(true);
        } else {
            setReCAPTCHAValid(false);
        }
    }

    const nextStep = async () => {



        if (!checkRef.current.checked) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debes estar de acuerdo con las políticas de autorización de uso de información',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return;
        }


        if (!personalDataObject.firstName || !personalDataObject.lastName || !personalDataObject.phone) {

            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe completar todos los campos',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

        }

        // if (!personalDataObject.id_origen) {
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: 'Advertencia',
        //         text: 'Debes seleccionar el origen.',
        //         cancelButtonColor: '#d33',
        //         cancelButtonText: 'Cancelar'
        //     })

        // }

        // if (!personalDataObject.plazo && typeProduct === 3) {

        //     return Swal.fire({
        //         icon: 'warning',
        //         title: 'Advertencia',
        //         text: 'Debe completar todos los campos',
        //         cancelButtonColor: '#d33',
        //         cancelButtonText: 'Cancelar'
        //     })

        // }

        if (personalDataObject.typeSubProducto === undefined) {

            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Seleccion el tipo de producto.',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

        }

        const catchaVerificado = await saveRequest()

        if (catchaVerificado === false) {
            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'reCAPTCHA incorrecto.',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
        }

        // const rValid = validation();
        // setResValid(rValid);
        // if (!rValid.valid) {
        //     return;
        // }
        setLoading(true);

        let identity = personalDataObject.id.replace("-", "");
        identity = identity.replace("-", "");


        // if (!validCedula(identity)) {
        //     setLoading(false)
        //     return Swal.fire({
        //         icon: 'warning',
        //         title: 'Advertencia',
        //         text: 'Cedula no valida.',
        //         cancelButtonColor: '#d33',
        //         cancelButtonText: 'Cancelar'
        //     });
        // }
        

        dispatch(setValue({ key: 'idFormatted', value: identity }));

        try {
            // dispatch(setValue({ key: 'applyingLabor', value: true }));
            // navigate('/domiciled');

            await save(identity)

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }


    const save = async (identity) => {

        const formData = new FormData();

        try {


            const dataEncargado = await AxiosBackend_API.get(`/encargadoBySucursal/${domiciledObject.sucursal}`, {
                headers: { 'token': token }
            })

            const encargado = dataEncargado.data[0]?.id_usuario
            const emailEncargado = dataEncargado.data[0]?.email
            const nombreEncargado = dataEncargado.data[0]?.nombre

            // if (!encargado) {

            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'Advertencia',
            //         text: "Error al enviar la solicitud. Intente mas tarde.",
            //         cancelButtonColor: '#d33',
            //         cancelButtonText: 'Cancelar'
            //     })

            //     return console.log('No hay encargado para esta sucursal')
            // }

            let response = 0

            try {

                // console.log( personalDataObject )

                // response = await AxiosBackend_API_PB3.get(`/getDatosJCE2WEB/1/${personalDataObject.idFormatted}/${personalDataObject.email}/${personalDataObject.phone}/1`, {
                response = await AxiosBackend_API_PB3.get(`/getDatosJCE2WEB/1/${identity ? identity : 'N/A'}/${personalDataObject.email ? personalDataObject.email : "NO-CORREO"}/${personalDataObject.phone ? personalDataObject.phone : 'N/A'}/1`, {
                    headers: {
                        'token': token
                    }
                });

                setIdPersona(response.data)

            } catch (error) {

                console.log(error.response.data.error)

                if (error.response.data.error === 'Token Inválido') {
                    return Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'Error al enviar la solicitud. Intente mas tarde.',
                        cancelButtonColor: '#d33',
                        // cancelButtonText: 'Cancelar'
                    })
                }
            }


            const dataToApi = {
                'id_sector': domiciledObject.city,
                // 'id_zona': 1,
                "id_persona": response.data,
                "cedula": identity,
                "telefono": personalDataObject.phone,
                "nombre_solicitante": personalDataObject.firstName + " " + personalDataObject.lastName,
                "id_sub_origen": laborAcademicObject.sourceIncome ? parseInt(laborAcademicObject.sourceIncome) : 999,
                "id_origen": parseInt(process.env.REACT_APP_DIA_MADRES_ID), // tabla lst_origen, id 7 = WEB
                "id_sub_producto": personalDataObject.typeSubProducto,
                "monto": personalDataObject.montoASolicitar,
                "plazo": personalDataObject.plazo > 0 ? personalDataObject.plazo * 12 : 0,
                "ingresos_mensuales": parseInt(laborAcademicObject.income),
                "id_producto": typeProduct ? typeProduct : parseInt(3),
                "id_estado_general": 1,
                "usuario_registro": 1,
                "id_oficial": selectedOficial ? selectedOficial : encargado,
                "email_encargado": emailEncargado,
                "nombre_encargado": nombreEncargado,
                "id_sucursal": domiciledObject.sucursal,
                "fecha_inicio_trabajo": parseInt(laborAcademicObject.startDate),
                "calle": domiciledObject.calle,
                "casa": domiciledObject.casa,
                "id_sub_grupo_actividad": laborAcademicObject.subGroupActivity ? parseInt(laborAcademicObject.subGroupActivity) : 999,
                "id_profesion": laborAcademicObject.profession ? parseInt(laborAcademicObject.profession) : 999,
                "id_clase_origen": laborAcademicObject.id_clase_origen ? parseInt(laborAcademicObject.id_clase_origen) : 999,
            }

            console.log(dataToApi)

            const resource = await AxiosBackend_API.post('/oportunidades-madres', dataToApi)
            const data = await resource.data;

            if (data.code === 409 && data.status === "ok") {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.message,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                })
            }

            if (resource.status === 404) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: resource.statusText,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                })
            }

            if (!resource.status === "201") {
                setLoading(false);
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: resource.statusText,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                });
            }

        } catch (error) {
            setLoading(false);
        }

        navigate('/request/result/success')

    }




    const handleChangeASolicitar = ({ target }) => {

        // alert(target)

        // const newValue = target.value;

        // if (newValue.length > 7) {
        //     newValue = newValue.slice(0, 7);
        // }

        // if (/^\d*$/.test(newValue)) {
        //     setMontoASolicitar(target.value)
        //     dispatch(setValue({ key: 'montoASolicitar', value: parseInt(target.value) }))
        // }

        setMontoASolicitar(target.value)
        dispatch(setValue({ key: 'montoASolicitar', value: parseInt(target.value) }))

    }

    const getSubProductos = async () => {

        let tokenPage = ""

        if (!token) tokenPage = await getLogin()

        let tipoProducto = 3

        if (typeProduct) tipoProducto = typeProduct

        const { data } = await AxiosBackend_API(`/sub-productos/${tipoProducto}`, {
            headers: { 'token': token.length > 0 ? token : tokenPage }
        })

        setSubProductos([
            { value: 'default', text: 'Seleccione el tipo...' },
            ...data
        ])

    }

    const getOrigenContactCenter = async () => {


        const { data } = await AxiosBackend_API(`/getOrigenContactCenter`, {
            headers: { 'token': token }
        })

        setOrigenContactCenter([
            { value: 'default', text: 'Seleccione el origen...' },
            ...data
        ])
    }


    const handleChangeOrigen = ({ value, name }) => {

        if (value) {
            setIdOrigen(true)
            dispatch(setValue({ key: 'id_origen', value: parseInt(value) }))

        }

    }

    const getLogin = async () => {


        const loginParams = {
            userName: process.env.REACT_APP_USERNAME_LOGIN,
            password: process.env.REACT_APP_PASSWORD_LOGIN
        }

        const response = await AxiosBackend_API_PB3.post('/login', loginParams)

        if (response.data.token) {
            // console.log(response.data.token)
            dispatch(setValueLogin({ key: 'token', value: response.data.token }))

            return response.data.token

        }

        // return console.log("No se genero el token")

    }

    useEffect(() => {
        getSubProductos()

        dispatch(setValue({ key: 'id_origen', value: parseInt(origen) }))

        // alianza = 6
        // link-qr = 41 en qa

        //si tipo producto = prestamo siempre el sub producto sera 203
        // y si origen = link-qr, dia de las madres o alianza sera tambien 2003
        // if () dispatch(setValuePersonal({ key: 'typeSubProducto', value: parseInt(203) }))

        if (typeProduct === 3 || (origen === 6) || origen === process.env.REACT_APP_LINK_QR_ID || origen === process.env.REACT_APP_DIA_MADRES_ID) dispatch(setValue({ key: 'typeSubProducto', value: parseInt(203) }))



        if (personalDataObject.id_origen === 999) {

            getOrigenContactCenter()
        }

    }, [])

    const esContactCenter = () => {

        if (personalDataObject.id_origen === 999 || personalDataObject.id_origen === 3 || personalDataObject.id_origen === 5) return true

        return false
    }

    return (
        <>
            <Row className="justify-content-md-center" style={{ marginTop: '-33px' }}>
                <FormGroup
                    colSize={12}
                    type="text"
                    placeholder="Primer Nombre"
                    value={personalDataObject.firstName}
                    onChange={(e) => dispatch(setValue({ key: 'firstName', value: e.target.value }))}
                    // inputTextValid={resValid}
                    className="my-2"
                    id="firstName"
                />
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
                <FormGroup

                    colSize={12}
                    type="text"
                    placeholder="Primer Apellido"
                    value={personalDataObject.lastName}
                    onChange={(e) => dispatch(setValue({ key: 'lastName', value: e.target.value }))}
                    // inputTextValid={resValid}
                    className="my-2"
                    id="lastName"
                />
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
                {/* <FormGroup 
                    colSize={12}
                    type="number"
                    placeholder="Cédula"
                    value={personalDataObject.id}
                    onChange={(e)=>dispatch(setValue({key:'id',value:e.target.value}))}
                    inputTextValid={resValid}
                    id="id"
                /> */}
                <MarkInputGroup
                    colSize={12}
                    type="number"
                    placeholder="Cédula"
                    value={personalDataObject.id}
                    // value={"031-0572379-9"}
                    onChange={(e) => dispatch(setValue({ key: 'id', value: e.target.value }))}
                    inputTextValid={resValid}
                    id="id"
                    mask="999-9999999-9"
                />
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
                <FormGroup
                    colSize={12}
                    type="text"
                    placeholder="Correo Electrónico"
                    value={personalDataObject.email}
                    onChange={(e) => dispatch(setValue({ key: 'email', value: e.target.value }))}
                    inputTextValid={resValid}
                    id="email"
                />
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
                <MarkInputGroup
                    colSize={12}
                    type="number"
                    placeholder="Número de Celular"
                    value={personalDataObject.phone}
                    // value="849-260-1730"
                    onChange={(e) => dispatch(setValue({ key: 'phone', value: e.target.value }))}
                    inputTextValid={resValid}
                    id="phone"
                    mask="999-999-9999"
                    refElement={phoneCellRef}
                />
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
            </Row>



            {/* <Row>
                <Col className="mb-2" style={{ marginTop: "10px" }}>
                    <h5>Datos de la solicitud</h5>
                </Col>
            </Row> */}



            {/* {
                personalDataObject.typeSubProducto === 203 ? null
                    :

                    <Row className="justify-content-md-center" style={{ marginTop: "-6px" }}>
                        <FormGroupSelect
                            colSize={12}
                            options={subProductos}
                            // defaultValue={personalDataObject.typeSubProducto}
                            onChange={(e) => dispatch(setValue({ key: 'typeSubProducto', value: parseInt(e.target.value) }))}
                        // disabled={personalDataObject.typeSubProducto}
                        />
                    </Row>

            } */}

            {/* <Row className="justify-content-md-center" style={{ marginTop: "-7px" }}>

                <InputNumber
                    // value={montoASolicitar}
                    // onValueChange={(e) => handleChangeASolicitar(e)}
                    onValueChange={(e) => dispatch(setValue({ key: 'montoASolicitar', value: parseInt(e.value) }))}
                    name="montoASolicitar"
                    minFractionDigits={0} maxFractionDigits={5}
                    maxLength={10}
                    placeholder="Monto a solicitar"
                    min={1}
                />


            </Row> */}
            {/* {
                typeProduct === 3 || personalDataObject.typeSubProducto === 203 ?
                    <Row className="justify-content-md-center" style={{ marginTop: "15px" }}>
                        <FormGroup
                            colSize={12}
                            type="text"
                            placeholder="Plazo de la solicitud (Años)"
                            // value={laborAcademicObject.income}
                            // onChange={(e) => dispatch(setValue({ key: 'income', value: e.target.value }))}
                            onChange={(e) => handleChangePlazo(e)}
                            value={plazo}
                            name="plazo"
                        />
                       
                    </Row>
                    : null
            } */}

            {
                esContactCenter() ?
                    <Row className="justify-content-md-center" style={{ marginTop: "-7px" }}>
                        <FormGroupSelect
                            colSize={12}
                            options={origenContactCenter}
                            defaultValue={personalDataObject.id_origen}
                            onChange={(e) => handleChangeOrigen(e.target)}
                        />
                    </Row>
                    : null
            }


            <Row className="justify-content-md-center" style={{ marginTop: '10px' }}>
                <Col md={12} className="mb-4">
                    <Check
                        text={<><span className="label-term">Estoy de acuerdo con la política de </span><a href='!#' className="label-term" onClick={(e) => { e.preventDefault(); setShowModal(true); }}>autorización de uso de información</a></>}
                        reference={checkRef}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={12} className="mb-4">
                    {/* <Button variant="warning" style={{width: '40%',height: '10vh'}}>CATCHA</Button> */}
                    <ReCAPTCHA
                        // sitekey="6LfPAhEjAAAAAAjiG9Vci2VO_hLDuJ5SrIxEItpi"
                        sitekey={` ${process.env.REACT_APP_CAPTPCHA_KEY} `}
                        onChange={reCaptchaOnChange}
                    />
                </Col>
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: '-1px' }}>
                <Col md={12} className="mb-4">
                    {
                        loading ?
                            <ReactBootstrapLoadingButton className="long-button bg-primary-color" />
                            :
                            <ReactBootstrapButton
                                text="ENVIAR"
                                variant="primary"
                                className="long-button bg-primary-color"
                                onClick={() => nextStep()}
                            />
                    }
                </Col>
            </Row>





            {/* <Row className="justify-content-md-center ">
                <Col md={12} className="mb-4 d-flex flex-column align-items-end">
                    {
                        loading ?
                            <ReactBootstrapLoadingButton className="long-button bg-primary-color" />
                            : <ReactBootstrapButton
                                text="SOLICITALO AQUI"
                                variant="primary"
                                className="long-button bg-primary-color"
                                onClick={() => saveRequest()}
                            />
                    }
                </Col>
            </Row> */}



            <CustomModal
                tittle="Autorización de uso de información"
                body={
                    <p>
                        Autorizo a BANCO DE AHORRO Y CRÉDITO FONDESA, S.A. (BANFONDESA) a consultar las         bases de datos de los Buró de Información Crediticia con la finalidad de verificar toda la         información suministrada con respecto a esta solicitud y las posteriores.
                    </p>
                }
                show={showModal}
                handleClose={setShowModal}
            />
        </>
    );
}
PersonalInfoMadresView.propTypes = {
    PersonalInfoMadresView: PropTypes.object
}
export default PersonalInfoMadresView;