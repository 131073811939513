import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
const CustomModal = (props) => {
    const { tittle, body, footer, show, handleClose } = props;
    return (
        <Modal show={show} onHide={()=>handleClose(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{tittle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                {footer}
                <Button variant="secondary" onClick={()=>handleClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
CustomModal.propTypes = {
    tittle: PropTypes.string.isRequired,
    body: PropTypes.any,
    footer: PropTypes.any,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default CustomModal;