import React, { useEffect, useState } from "react";
import ResultRequest from "../../Templates/ResultRequest";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faLandmarkAlt, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "../../../features/counter/typeProductSlice";
import { setValue as setValuePersonal } from "../../../features/counter/personalDataSlice";
import { AxiosBackend_API, AxiosBackend_API_PB3 } from "../../../config/Axios";

import FormGroupSelect from "../../Molecules/ReactBootstrapFormGroupSelect";

import { setValue as setValueLogin } from "../../../features/counter/loginSlice";


const Menu = () => {

    const personalDataObject = useSelector((state) => state.personalData.value)

    const dispatch = useDispatch()

    const navigate = useNavigate();


    const [productos, setProductos] = useState([])
    const [origenContactCenter, setOrigenContactCenter] = useState([])


    const getProductos = async () => {

        const loginParams = {
            userName: process.env.REACT_APP_USERNAME_LOGIN,
            password: process.env.REACT_APP_PASSWORD_LOGIN
        }

        const response = await AxiosBackend_API_PB3.post('/login', loginParams)

        // console.log( response.data.token )
        //     navigate('/domiciled');
        if (response.data.token) dispatch(setValueLogin({ key: 'token', value: response.data.token }))

        const { data } = await AxiosBackend_API.get('/productos', {
            headers: {
                'token': response.data.token
            }
        })
        // const { data } = await axios.get('http://localhost:3124/api/solicitud/productos')

        if (data) {
            // console.log('hizo el dispatch de id_origen')
            dispatch(setValuePersonal({ key: 'id_origen', value: parseInt(7) }))
        }

        setProductos(data)

    }

    const handleNavigate = id => {

        dispatch(setValue(id))

        // if (id === 3) dispatch(setValuePersonal({ key: 'typeSubProducto', value: parseInt(203) }))

        navigate('/loan')
    }

    useEffect(() => {

        getProductos()

    }, [])

    const getColor = id => {

        switch (id) {
            case 1:
                return "blue";
            case 3:
                return "orange";
            case 4:
                return "bbBlue";
            default:
                return "blue";

        }
    }

    const getIcon = id => {

        switch (id) {
            case 1:
                return faPiggyBank;
            case 3:
                return faLandmarkAlt;
            case 4:
                return faCertificate;
            default:
                return faCertificate;

        }
    }


    return (
        <ResultRequest
            content={
                <>
                    <Row style={{ marginTop: '7%' }}>
                        <Col md={12} lg={12}>
                            <h3 className="text-white" style={{ textAlign: 'center', marginBottom: '2.5rem' }}>¿Cuál de nuestros productos te gustaría solicitar?</h3>
                        </Col>

                        {
                            productos.map(({ id_producto, descripcion }, index) => (
                                <Col md={12} lg={4} onClick={() => handleNavigate(id_producto)} key={index + 1} style={{ marginTop: "15px" }}>
                                    <Card className={`menu-options menu-options-${getColor(id_producto)}`}>
                                        <Card.Body>
                                            <Card.Title className="menu-tittle">{descripcion}</Card.Title>
                                            <Card.Text style={{ textAlign: 'right', opacity: '0.5' }}>
                                                <FontAwesomeIcon icon={getIcon(id_producto)} style={{ fontSize: '72px' }} />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }

                    </Row>
                </>
            }
        />
    )
}

export default Menu;