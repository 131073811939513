import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Image, Spinner } from "react-bootstrap";
import PropTypes from 'prop-types';
import PersonalInfoPuetoPaMiBancoView from "../personalInfoPuetoPaMiBancoView";
import CustomCard from "../../Components/Templates/CustomCard";

const PuetoPaMiBancoPage = (props) => {
    const
        { productType } = props,
        [defaultData, setDefaultData] = useState({}),
        [loading, setLoading] = useState(false);

    /*
useEffect(() =>
    getGeneralData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
    */
    const getGeneralData = async () => {
        setLoading(true);
        try {

            // const resource = await axios.get(paths.generalData),
            //     data = await resource.data;
            // data['productType'] = productType;
            setDefaultData({});

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }


    }
    return (

        <Container fluid>
            <Row>
                <Col md={12} lg={6}>
                    <Navbar.Brand href="/#/madres"><Image src="/bgLogo.png" style={{ width: "100%" }} /></Navbar.Brand>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Image src="/LOGO-NEW.png" className="sub-img" />
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6} className="right-content-side">
                    <Row className="justify-content-md-center sub-content-side">
                        <Col md={9} style={{ padding: "2%" }}>
                            {/* <PersonalInfoView defaultData={defaultData} origen={process.env.REACT_APP_DIA_MADRES_ID} loading={loading} /> */}

                            <CustomCard
                                text={"Completa la información solicitada"}
                                content={
                                    <>
                                        {
                                            loading ?
                                                <Row className="justify-content-center">
                                                    <Col md={2} className="mb-3">
                                                        <Spinner animation="grow" role="status" className="string-primary-color" style={{ marginBottom: '5px' }} />
                                                        <span className="visually-hidden">Cargando información, por favor espere...</span>
                                                    </Col>
                                                </Row>
                                                : null
                                        }

                                        {/* PERSONAL PAGE HERE */}

                                        <PersonalInfoPuetoPaMiBancoView defaultData={defaultData} origen={`${process.env.REACT_APP_PUETOPAMIBANCO_ID}`} loading={loading} />

                                        {/* PERSONAL PAGE HERE */}

                                    </>
                                }
                            />

                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default PuetoPaMiBancoPage;
