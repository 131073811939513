import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
const InputText = (props) => {
    const {
        text,
        className,
        style
    } = props;
    return <Form.Text className={className} style={style}>{text}</Form.Text>;
}
InputText.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
}
export default InputText;