import { createSlice } from "@reduxjs/toolkit";

export const personalDataSlice = createSlice({
    name: 'personalData',
    initialState: {
        value: {
            request_id: 0,
            firstName: '',
            lastName: '',
            id: '',
            idFormatted: '',
            email: '',
            phone: '',
            branchOffice: 0,
            applying: false,
            id_origen: 0
        }
    },
    reducers: {
        setValue: (state, action) => {
            state.value = {
                ...state.value,
                [action.payload.key]: action.payload.value
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValue } = personalDataSlice.actions

export default personalDataSlice.reducer