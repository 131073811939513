import React from "react";
import { Container, Card, Image, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
const CustomCard = (props) => {
    const { text, content } = props;
    return (
        <Card 
        // className="content-card" 
        // style={{ overflowY: 'auto' }}
        >
            <Card.Body style={{ paddingBottom: '0px', paddingTop: '5%' }}>
                {
                    text ? <Card.Title className="card-tittle mb-5" style={{ textTransform: 'uppercase' }}>{text}</Card.Title>
                        : null
                }

                <Container fluid 
                // className="content-container "
                >
                    {content}
                    {/* <Row className="justify-content-end">
                        <Col md={4} xs={4}>
                            <Image src="/logo_b.jpg" style={{ width: "100%" }} />
                        </Col>
                    </Row> */}
                </Container>
            </Card.Body>
            {/* <Card.Body style={{padding: '1px'}}>
                <Row className="justify-content-end">
                    <Col md={4} xs={4}>
                        <Image src="/logo_b.jpg" style={{width: "100%"}} />
                    </Col>
                </Row>
            </Card.Body> */}
        </Card>
    )
}
CustomCard.propTypes = {
    text: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired
}
export default CustomCard;