import React from "react";
import { Container, Row, Navbar, Image, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
const ResultRequest = (props) => {
    const { content } = props;
    return (
        <Container>
            <Row>
                <Col md={12} lg={6}>
                    <Navbar.Brand href="/"><Image src="/bgLogo.png" style={{width: "100%"}} /></Navbar.Brand>
                </Col>
            </Row>
            {content}
        </Container>
    );
}

ResultRequest.propTypes = {
    content: PropTypes.any
}

export default ResultRequest;