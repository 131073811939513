import React from "react";
import ResultRequest from "../../Templates/ResultRequest";
import { Row, Col } from "react-bootstrap";
const LoadingLr = () => {
    return (
        <ResultRequest 
            content={
                <>
                    <Row style={{marginTop: '12%'}}>
                        <Col style={
                            {
                                textAlign: 'center',
                                fontSize: '32px',
                                color: '#fff'
                            }
                        }>
                            Usted será redireccionado<br />
                            por favor espere...
                        </Col>
                    </Row>
                </>
            }
        />
    )
}

export default LoadingLr;