import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
const Input = (props) => {
    const {
        type,
        size,
        placeholder,
        value,
        onChange,
        onKeyDown,
        ref
    } = props;
    return <Form.Control 
                type={type}
                size={(size!==undefined&&size!==null)?size:"md"}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                ref={ref}
            />;
}
Input.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
}
export default Input;