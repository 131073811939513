import { createSlice } from "@reduxjs/toolkit";

export const laborAcademicSlice = createSlice({
    name: 'laborAcademic',
    initialState: {
        value: {
            sourceIncome: 0,
            income: '',
            jobPerform: 0,
            startDate: '',
            academicLevel: 0,
            profession: 0,
            applyingLabor: true,
            subGroupActivity: 0,
            id_clase_origen: 0
        }
    },
    reducers: {
        setValue: (state, action) => {
            state.value = {
                ...state.value,
                [action.payload.key]: action.payload.value
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValue } = laborAcademicSlice.actions

export default laborAcademicSlice.reducer