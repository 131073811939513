import axios from "axios";

export const AxiosBackend_API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {
    'X-Forwarded-Proto': 'https',
    'X-Frame-Options': 'deny'
  }
  // baseURL: "http://localhost:5000/api/solicitud",
});

export const AxiosBackend_API_PB3 = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_JCE,
  headers: {
    'X-Forwarded-Proto': 'https',
    'X-Frame-Options': 'deny'
  }
});

// export default AxiosBackend_API, AxiosBackend_API_PB3

