import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ReactBootstrapButton from "../../Atoms/ReactBootstrapButton";
import ReactBootstrapLoadingButton from "../../Atoms/ReactBootstrapLoadingButton";
import FormGroup from "../../Molecules/ReactBootstrapFormGroupInput";
import FormGroupSelect from "../../Molecules/ReactBootstrapFormGroupSelect";
import FormGroupDate from "../../Molecules/ReactBootstrapFormGroupDate";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setValue } from "../../../features/counter/laborAcademicSlice";
import { setValueSubProduct } from "../../../features/counter/typeSubProductSlice";
import Swal from "sweetalert2";
import validator from "validator";
import axios from "axios";
import { paths } from "../../../Models/general.model";
import ReactSelectGroup from "../../Molecules/ReactSelectGroup";
import { AxiosBackend_API } from "../../../config/Axios";
const defaultValid = {
    key: '',
    text: '',
    valid: true
};
const LaborAcademics = (props) => {
    const { defaultData } = props,
        navigate = useNavigate(),
        laborAcademicObject = useSelector((state) => state.laborAcademic.value),
        personalDataObject = useSelector((state) => state.personalData.value),
        typeProduct = useSelector((state) => state.typeProduct.value),
        dispatch = useDispatch(),
        [resValid, setResValid] = useState(defaultValid),
        [loading, setLoading] = useState(false),
        [subProductos, setSubProductos] = useState([]),
        [typeSubProducto, setTypeSubProducto] = useState(0),
        [plazo, setPlazo] = useState(0),
        [montoASolicitar, setMontoASolicitar] = useState(0);
    useEffect(() => {
        const rValid = validation();
        setResValid(rValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [laborAcademicObject]);

    const getSubProductos = async () => {

        const { data } = await AxiosBackend_API(`/sub-productos/${typeProduct}`)

        // console.log( resource.data )
        setSubProductos(data)
    }

    useEffect(() => {
        getSubProductos()
    }, [])

    const validation = () => {
        if (laborAcademicObject.sourceIncome.length === undefined || laborAcademicObject.sourceIncome.length === 0) {
            return { key: 'sourceIncome', text: 'Debe seleccionar la fuente de ingreso', valid: false };
        }
        if (isNaN(parseFloat(laborAcademicObject.income)) || parseFloat(laborAcademicObject.income) <= 0) {
            return { key: 'income', text: 'Debe indicar sus ingresos', valid: false };
        }


        // if(parseFloat(laborAcademicObject.jobPerform)<1){
        //     return {key: 'jobPerform', text: 'Debe indicar el cargo que desempeña', valid: false};
        // }
        // if (!validator.isDate(laborAcademicObject.startDate, { format: 'MM/DD/YYYY', strictMode: true, delimiters: ['/'] })) {
        //     return { key: 'startDate', text: 'Debe ingresar la fecha de inicio', valid: false };
        // }
        // if (parseFloat(laborAcademicObject.academicLevel) < 1) {
        //     return { key: 'academicLevel', text: 'Debe seleccionar el nivel de estudio', valid: false };
        // }
        // if (parseFloat(laborAcademicObject.profession) < 1) {
        //     return { key: 'profession', text: 'Debe seleccionar su profesión', valid: false };
        // }
        /*if(parseInt(laborAcademicObject.subGroupActivity)<1){
            return {key: 'subGroupActivity', text: 'Debe seleccionar su grupo de actividad', valid: false};
        }*/
        return { key: 'success', text: 'Todos los campos fueron validados', valid: true };
    }
    const nextStep = async () => {
        const rValid = validation();
        setResValid(rValid);
        if (!rValid.valid) {
            return;
        }
        setLoading(true);
        try {
            dispatch(setValue({ key: 'applyingLabor', value: true }));
            navigate('/domiciled');

            // let dataToSave = {
            //     ...laborAcademicObject,
            //     id: personalDataObject.idFormatted,
            //     request: personalDataObject.request_id
            // }
            // const resource = await axios.post(paths.requestLaborSave, dataToSave),
            //     data = await resource.data;
            // setLoading(false);
            // if (data.status) {
            //     dispatch(setValue({ key: 'applyingLabor', value: true }));
            //     navigate('/domiciled');
            // } else {
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'Advertencia',
            //         text: data.response,
            //         cancelButtonColor: '#d33',
            //         cancelButtonText: 'Cancelar'
            //     });
            // }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleChangePlazo = ({ target }) => {
        setPlazo(target.value)
        dispatch(setValue({ key: 'plazo', value: parseInt(target.value) }))
    }

    const handleChangeASolicitar = ({ target }) => {
        setMontoASolicitar(target.value)
        dispatch(setValue({ key: 'montoASolicitar', value: parseInt(target.value) }))
    }

    return (
        <>
            <Row>
                <Col className="mb-2" >
                    <h5>Datos de la solicitud</h5>
                </Col>
            </Row>
            <Row className="justify-content-md-center" >
                <FormGroupSelect
                    colSize={12}
                    options={subProductos}
                    // onChange={(e) => dispatch(setValueSubProduct(parseInt(e.target.value)))}
                    onChange={(e) => dispatch(setValue({ key: 'typeSubProducto', value: parseInt(e.target.value) }))}
                />

            </Row>
            <Row className="justify-content-md-center">
                <FormGroup
                    colSize={12}
                    type="text"
                    placeholder="Monto a solicitar"
                    // value={laborAcademicObject.income}
                    // onChange={(e) => dispatch(setValue({ key: 'income', value: e.target.value }))}
                    // inputTextValid={resValid}
                    // id="income"
                    onChange={(e) => handleChangeASolicitar(e)}
                    name="montoASolicitar"
                />
                {
                    !montoASolicitar > 0 ? <label style={{ marginTop: "-20px", marginBottom: "15px", marginLeft: "10px", color: "red", fontSize: "14px" }}>El monto debe ser completado</label>
                        : null
                }


            </Row>

            {



                typeProduct !== 1 ?
                    <Row className="justify-content-md-center">
                        <FormGroup
                            colSize={12}
                            type="number"
                            placeholder="Plazo de la solicitud"
                            // value={laborAcademicObject.income}
                            // onChange={(e) => dispatch(setValue({ key: 'income', value: e.target.value }))}
                            onChange={(e) => handleChangePlazo(e)}
                            name="plazo"
                        />
                        {
                            !plazo > 0 ? <label style={{ marginTop: "-20px", marginLeft: "10px", color: "red", fontSize: "14px" }}>El plazo debe ser completado</label>
                                : null
                        }
                        {/* <label style={{ marginTop: "-20px", marginLeft: "10px", color: "red", fontSize: "14px" }}>Plazo debe ser digitado en meses</label> */}
                    </Row>
                    : null
            }

            <Row className="mt-4">
                <Col className="mb-2">
                    <h5>Datos Laboral</h5>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <FormGroupSelect
                    colSize={12}
                    options={[
                        { id: '', text: "Fuente de ingresos" },
                        { id: 'Asalariado', text: "Asalariado" },
                        { id: 'Independiente', text: "Independiente" }
                    ]}
                    defaultValue={laborAcademicObject.sourceIncome}
                    onChange={(e) => dispatch(setValue({ key: 'sourceIncome', value: e.target.value }))}
                    inputTextValid={resValid}
                    id="sourceIncome"
                />
            </Row>
            <Row className="justify-content-md-center">
                <FormGroup
                    colSize={12}
                    type="text"
                    placeholder="Ingresos Mensuales"
                    value={laborAcademicObject.income}
                    onChange={(e) => dispatch(setValue({ key: 'income', value: e.target.value }))}
                    inputTextValid={resValid}
                    id="income"
                />
            </Row>
            <Row className="justify-content-md-center">
                <FormGroupDate
                    colSize={12}
                    format="DD-MM-YYYY"
                    inputProps={{
                        placeholder: "Fecha de inicio, DD/MM/YYYY",
                        readOnly: true,
                        style: { cursor: 'pointer' }
                        //disabled: true
                        //onMouseLeave: () => alert('You went to the input but it was disabled')
                    }
                    }
                    onChange={(e) => {
                        const date = new Date(e),
                            day = ("0" + (date.getDate())).slice(-2),
                            month = ("0" + (date.getMonth() + 1)).slice(-2),
                            year = date.getFullYear(),
                            dateSelected = month + '/' + day + '/' + year;
                        dispatch(setValue({ key: 'startDate', value: dateSelected }));
                    }}
                    inputTextValid={resValid}
                    id="startDate"
                />
            </Row>
            {/* <Row>
                <Col className="mb-4">
                    <h5>Datos Académicos</h5>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <FormGroupSelect
                    colSize={12}
                    options={defaultData.educationLevel}
                    value={laborAcademicObject.academicLevel}
                    onChange={(e)=>dispatch(setValue({key:'academicLevel',value:e.target.value}))}
                    inputTextValid={resValid}
                    id="academicLevel"
                />
            </Row>
            <Row className="justify-content-md-center">
               
                <ReactSelectGroup 
                    colSize={12}
                    options={defaultData.occupations}
                    // value={laborAcademicObject.profession}
                    defaultValue={{value: 0, label: 'Profesión'}}
                    onChange={(handleData)=>dispatch(setValue({key:'profession',value:handleData.value}))}
                    inputTextValid={resValid}
                    id="profession"
                />
            </Row> */}
            {/*<Row className="justify-content-md-center">
                <Col md={12} className="mb-3">
                    <ReactBootstrapButton 
                        text="VOLVER A DATOS PERSONALES"
                        variant="danger"
                        className="long-button"
                        onClick={()=>navigate("/")}
                    />
                </Col>
            </Row>*/}
            {/* <Row>
                <Col className="mb-4">
                    <h5>Otros Datos</h5>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <ReactSelectGroup 
                    colSize={12}
                    options={defaultData.subGroupActivityList}
                    // value={laborAcademicObject.profession}
                    defaultValue={{value: 0, label: 'Sub-Grupo Actividad'}}
                    onChange={(handleData)=>dispatch(setValue({key:'subGroupActivity',value:handleData.value}))}
                    inputTextValid={resValid}
                    id="subGroupActivity"
                />
            </Row> */}
            <Row className="justify-content-md-center">
                <Col md={12} className="mb-4">
                    {
                        loading ?
                            <ReactBootstrapLoadingButton className="long-button bg-primary-color" />
                            :
                                <ReactBootstrapButton
                                    text="SIGUIENTE"
                                    variant="primary"
                                    className="long-button bg-primary-color"
                                    onClick={() => nextStep()}
                                />
                    }
                </Col>
            </Row>
        </>
    );
}
LaborAcademics.propTypes = {
    defaultData: PropTypes.object
}
export default LaborAcademics;