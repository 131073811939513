import { createSlice } from "@reduxjs/toolkit";

export const typeSubProductSlice = createSlice({
    name: 'typeSubProduct',
    initialState: {
        typeSubProduct: 0
    },
    reducers: {
        setValueSubProduct: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValueSubProduct } = typeSubProductSlice.actions

export default typeSubProductSlice.reducer