import { createSlice } from "@reduxjs/toolkit";

export const DomiciledSlice = createSlice({
    name: 'domiciled',
    initialState: {
        value: {
            city: 0,
            livingPlace: 0,
            time: '',
            dependents: '',
            documentType: 0,
            sucursal: 0,
            calle: '',
            casa: '',
        }
    },
    reducers: {
        setValue: (state, action) => {
            state.value = {
                ...state.value,
                [action.payload.key]: action.payload.value
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValue } = DomiciledSlice.actions

export default DomiciledSlice.reducer