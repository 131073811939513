import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
const ReactSelect = (props) => {
    const 
        [stateOptions,setStateOptions] = useState(),
        {
            id,
            options,
            defaultValue,
            onChange
        } = props;    
    useEffect(()=>{
        let arr = [];
        if(options !== undefined && options !== null)
            options.map((element)=>(
                arr.push({
                    value: element.id,
                    label: element.text
                })
            ));
        setStateOptions(arr);
    },[options]);
    return <Select 
                id={id}
                options={stateOptions}
                defaultValue={defaultValue}
                onChange={onChange}
            />
}
ReactSelect.propTypes = {
    options: PropTypes.array,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func
}
export default ReactSelect;