import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "../../Atoms/ReactBootstrapSelect";
import InputText from "../../Atoms/ReactBootstrapInputText";
const FormGroupSelect = (props) => {
    const { 
        colSize, 
        options, 
        defaultValue, 
        onChange,
        inputTextValid,
        id,
        disabled
    } = props;

    return (
        <Col md={colSize}>
            <Form.Group className="mb-4">
                <Select 
                    options={options}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                />
                {                    
                    (
                        inputTextValid!==undefined&&
                        inputTextValid!==null&&
                        !inputTextValid.valid&&
                        id===inputTextValid.key
                    )?<InputText text={inputTextValid.text} className="text-danger" style={{marginLeft: '5px'}} />
                    :null
                }
            </Form.Group>        
        </Col>
    )
}
FormGroupSelect.propTypes = {
    colSize: PropTypes.number.isRequired,
    options: PropTypes.array,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func
}
export default FormGroupSelect;