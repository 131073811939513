import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Input from "../../Atoms/ReactBootstrapInput";
import InputText from "../../Atoms/ReactBootstrapInputText";
const FormGroup = (props) => {
    const { 
            colSize, 
            type, 
            size, 
            placeholder,
            value,
            onChange,
            inputTextValid,
            id,
            onKeyDown,
            ref
         } = props;
    return (
        <Col md={colSize}>
            <Form.Group className="mb-4">
                <Input 
                    type={type}
                    size={size}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={ref}
                />
                {
                    (
                        inputTextValid!==undefined&&
                        inputTextValid!==null&&
                        !inputTextValid.valid&&
                        id===inputTextValid.key
                    )?<InputText text={inputTextValid.text} className="text-danger" style={{marginLeft: '5px'}} />
                    :null
                }
            </Form.Group>        
        </Col>
    )
}
FormGroup.propTypes = {
    colSize: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    ref: PropTypes.any
}
export default FormGroup;