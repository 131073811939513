import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./app/store";
import MapComponent from "./Views/MyMapComponent";

// const api_key = process.env.GOOGLE_MAP_API_KEY;
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <Wrapper apiKey={process.env.GOOGLE_MAP_API_KEY} render={render}> */}
        <App />
        {/* <MapComponent /> */}
        
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
