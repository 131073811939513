import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Navbar, Image, Spinner, FormGroup } from "react-bootstrap";
import PersonalInfoView from "../personalInfoView";
import CustomCard from "../../Components/Templates/CustomCard";
import ReactBootstrapLoadingButton from "../../Components/Atoms/ReactBootstrapLoadingButton";
import { ReCAPTCHA } from "react-google-recaptcha";
import FormGroupSelect from "../../Components/Molecules/ReactBootstrapFormGroupSelect";
import MarkInputGroup from "../../Components/Molecules/MarkInputGroup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setValue as setValueLogin } from "../../../src/features/counter/loginSlice";
import { setValue } from "../../../src/features/counter/personalDataSlice";
import { setValue as generalSetValue } from "../../../src/features/counter/generalSlice";
import { setValue as laborAcademicSetValue } from "../../../src/features/counter/laborAcademicSlice";
import Swal from "sweetalert2";
import validator from "validator";
import ReactBootstrapButton from "../../Components/Atoms/ReactBootstrapButton";
import { InputNumber } from "primereact/inputnumber";
import CustomModal from "../../features/snippets/modal";
import Check from "../../Components/Atoms/ReactBootstrapCheck";
import { AxiosBackend_API, AxiosBackend_API_PB3 } from "../../config/Axios";
import PersonalInfoMadresView from "../personalInfoMadresView";

const defaultValid = {

    key: '',
    text: '',
    valid: true
};

const MadresPage = (props) => {
    const
        { productType } = props,
        [defaultData, setDefaultData] = useState({}),
        { token } = useSelector((state) => state.login.value),
        personalDataObject = useSelector((state) => state.personalData.value),
        laborAcademicObject = useSelector((state) => state.laborAcademic.value),
        typeProduct = useSelector((state) => state.typeProduct.value),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        checkRef = useRef(),
        [resValid, setResValid] = useState(defaultValid),
        [showModal, setShowModal] = useState(false),
        [loading, setLoading] = useState(false),
        [montoASolicitar, setMontoASolicitar] = useState(''),
        [reCAPTCHAValid, setReCAPTCHAValid] = useState(false),
        [plazo, setPlazo] = useState(''),
        [subProductos, setSubProductos] = useState([]),
        [origenContactCenter, setOrigenContactCenter] = useState([]),
        [idOrigen, setIdOrigen] = useState(false),
        [showOrigen, setShowOrigen] = useState(false),
        phoneCellRef = useRef();


    const getGeneralData = async () => {
        setLoading(true);
        try {

            setDefaultData({});

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }


    }

    useEffect(() => {
    }, [personalDataObject]);

    function validCedula(cedula) {
        if (typeof cedula != "string") return false

        //cleanup
        cedula = cedula.replace(/-/g, "");

        // La cédula debe tener 11 dígitos
        if (cedula.length !== 11) return false

        // Validar serie
        if (parseInt(cedula.substring(0, 3)) !== 402 && parseInt(cedula.substring(0, 3)) > 121 && parseInt(cedula.substring(0, 3)) < 1) return false

        var suma = 0
        var verificador = 0

        for (var i = 0; i < cedula.length; i++) {
            let n = cedula.charAt(i);
            //No ejecutar el ultimo digito
            if (i === (cedula.length) - 1) break;

            // Los dígitos pares valen 2 y los impares 1
            let multiplicador = (parseInt(i) % 2) === 0 ? 1 : 2;

            // Se multiplica cada dígito por su paridad
            let digito = parseInt(n) * parseInt(multiplicador)

            // Si la multiplicación da de dos dígitos, se suman entre sí
            digito = digito > 9 ? [...digito.toString()].map(e => parseInt(e)).reduce((a, b) => a + b) : digito;

            // Se va haciendo la acumulación de esa suma
            suma = suma + digito
        }
        // Al final se obtiene el verificador con la siguiente fórmula
        verificador = (10 - (suma % 10)) % 10

        // Se comprueba el verificador
        return (verificador === parseInt(cedula.slice(-1)))
    }

    const saveRequest = async () => {
        const rValid = validation();
        setResValid(rValid);
        if (!rValid.valid) {
            return;
        }
        if (!reCAPTCHAValid) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Los datos del reCAPTCHA no son validos',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return false;
        }
        if (!checkRef.current.checked) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debes estar de acuerdo con las políticas de autorización de uso de información',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return;
        }

        setLoading(true);

        try {

            dispatch(setValue({ key: 'applying', value: true }));


            setLoading(false);


        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleChangePlazo = ({ target }) => {

        const newValue = target.value;

        if (newValue.length > 2) {
            newValue = newValue.slice(0, 2);
        }

        if (/^\d*$/.test(newValue)) {
            setPlazo(target.value)
            dispatch(setValue({ key: 'plazo', value: parseInt(target.value) }))
        }


    }

    const validation = () => {
        if (!validator.isAlpha(personalDataObject.firstName, ['es-ES'])) {
            return { key: 'firstName', text: 'Favor ingresar su primer nombre', valid: false };
        }
        if (!validator.isAlpha(personalDataObject.lastName, ['es-ES'])) {
            return { key: 'lastName', text: 'Favor ingresar su primer apellido', valid: false };
        }
        let identity = personalDataObject.id.replace("-", "");
        identity = identity.replace("-", "");
        identity = identity.replace("_", "");
        if (!validator.isLength(identity, { min: 11, max: 11 })) {
            return { key: 'id', text: 'Favor ingresar un numero de cédula valida', valid: false };
        }
        if (!validator.isEmail(personalDataObject.email)) {
            return { key: 'email', text: 'Favor ingresar un correo electrónico valido', valid: false };
        }
        /** check station */
        let serie = personalDataObject.phone.substr(0, 3);
        const serieArr = ['809', '829', '849'];
        if (!serieArr.includes(serie)) {
            return { key: 'phone', text: 'La serie del numero de celular no es valida', valid: false };
        }

        let cellPhone = personalDataObject.phone.replace("-", "");
        cellPhone = cellPhone.replace("-", "");
        cellPhone = cellPhone.replace("_", "");
        if (!validator.isLength(cellPhone, { min: 10, max: 10 })) {
            return { key: 'phone', text: 'Favor ingresar un numero de celular valido', valid: false };
        }
        if (!validator.isMobilePhone(cellPhone)) {
            return { key: 'phone', text: 'El numero de celular no es valido', valid: false };
        }

        return { key: 'success', text: 'Todos los campos fueron validados', valid: true };
    }
    const reCaptchaOnChange = (value) => {
        //console.log("value>>",value);

        if (value !== null && value !== undefined) {
            setReCAPTCHAValid(true);
        } else {
            setReCAPTCHAValid(false);
        }
    }


    const handleChangeOrigen = ({ value, name }) => {

        if (value) {
            setIdOrigen(true)
            dispatch(setValue({ key: 'id_origen', value: parseInt(value) }))

        }

    }

    const nextStep = async () => {



        if (!checkRef.current.checked) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debes estar de acuerdo con las políticas de autorización de uso de información',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
            return;
        }


        if (!personalDataObject.firstName || !personalDataObject.lastName || !personalDataObject.id || !personalDataObject.phone || !personalDataObject.montoASolicitar) {

            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe completar todos los campos',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

        }

        if (!personalDataObject.plazo && typeProduct === 3) {

            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe completar todos los campos',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

        }

        if (personalDataObject.typeSubProducto === undefined) {

            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Seleccion el tipo de producto.',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

        }

        const catchaVerificado = await saveRequest()

        if (catchaVerificado === false) {
            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'reCAPTCHA incorrecto.',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
        }

        setLoading(true);

        let identity = personalDataObject.id.replace("-", "");
        identity = identity.replace("-", "");


        if (!validCedula(identity)) {
            setLoading(false)
            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Cedula no valida.',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            });
        }
        dispatch(setValue({ key: 'idFormatted', value: identity }));

        try {
            dispatch(setValue({ key: 'applyingLabor', value: true }));
            navigate('/domiciled');

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }


    useEffect(() => {
        getSubProductos()

        dispatch(setValue({ key: 'id_origen', value: parseInt(process.env.REACT_APP_DIA_MADRES_ID) }))


        // if (typeProduct === 3 || (origen === 6) || origen === process.env.REACT_APP_LINK_QR_ID || origen === process.env.REACT_APP_DIA_MADRES_ID) dispatch(setValue({ key: 'typeSubProducto', value: parseInt(203) }))


        // if (personalDataObject.id_origen === 999) {

        //     getOrigenContactCenter()
        // }

    }, [])

    const getSubProductos = async () => {

        let tokenPage = ""

        if (!token) tokenPage = await getLogin()

        let tipoProducto = 3

        if (typeProduct) tipoProducto = typeProduct

        const { data } = await AxiosBackend_API(`/sub-productos/${tipoProducto}`, {
            headers: { 'token': token.length > 0 ? token : tokenPage }
        })

        setSubProductos([
            { value: 'default', text: 'Seleccione el tipo...' },
            ...data
        ])

    }

    const getLogin = async () => {


        const loginParams = {
            userName: process.env.REACT_APP_USERNAME_LOGIN,
            password: process.env.REACT_APP_PASSWORD_LOGIN
        }

        const response = await AxiosBackend_API_PB3.post('/login', loginParams)

        if (response.data.token) {
            // console.log(response.data.token)
            dispatch(setValueLogin({ key: 'token', value: response.data.token }))

            return response.data.token

        }

        // return console.log("No se genero el token")

    }



    return (
        <Container fluid>
            <Row>
                <Col md={12} lg={6}>
                    <Navbar.Brand href="/#/madres"><Image src="/bgLogo.png" style={{ width: "100%" }} /></Navbar.Brand>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Image src="/LOGO-NEW.png" className="sub-img" />
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6} className="right-content-side">
                    <Row className="justify-content-md-center sub-content-side">
                        <Col md={9} style={{ padding: "2%" }}>
                            {/* <PersonalInfoView defaultData={defaultData} origen={process.env.REACT_APP_DIA_MADRES_ID} loading={loading} /> */}

                            <CustomCard
                                text={"Completa la información solicitada"}
                                content={
                                    <>
                                        {
                                            loading ?
                                                <Row className="justify-content-center">
                                                    <Col md={2} className="mb-3">
                                                        <Spinner animation="grow" role="status" className="string-primary-color" style={{ marginBottom: '5px' }} />
                                                        <span className="visually-hidden">Cargando información, por favor espere...</span>
                                                    </Col>
                                                </Row>
                                                : null
                                        }


                                        {/* PERSONAL PAGE HERE */}


                                        <PersonalInfoMadresView defaultData={defaultData} origen={process.env.REACT_APP_DIA_MADRES_ID} />




                                        {/* PERSONAL PAGE HERE */}


                                    </>
                                }
                            />


                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MadresPage;