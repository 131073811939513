import { createSlice } from "@reduxjs/toolkit";

export const typeProductSlice = createSlice({
    name: 'typeProduct',
    initialState: {
        typeProduct: 0
    },
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValue } = typeProductSlice.actions

export default typeProductSlice.reducer