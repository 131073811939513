import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import InputText from "../../Atoms/ReactBootstrapInputText";
import ReactSelect from "../../Atoms/ReactSelect";
const ReactSelectGroup = (props) => {
    const { 
        colSize, 
        options, 
        defaultValue, 
        onChange,
        inputTextValid,
        id 
    } = props;

    return (
        <Col md={colSize}>
            <Form.Group className="mb-4">
                <ReactSelect 
                    id={id}
                    options={options}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled
                />
                {                    
                    (
                        inputTextValid!==undefined&&
                        inputTextValid!==null&&
                        !inputTextValid.valid&&
                        id===inputTextValid.key
                    )?<InputText text={inputTextValid.text} className="text-danger" style={{marginLeft: '5px'}} />
                    :null
                }
            </Form.Group>        
        </Col>
    )
}
ReactSelectGroup.propTypes = {
    colSize: PropTypes.number.isRequired,
    options: PropTypes.array,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func
}
export default ReactSelectGroup;