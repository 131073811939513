import React from "react";
import ResultRequest from "../../Templates/ResultRequest";
import { Row, Col } from "react-bootstrap";

import { useSelector } from "react-redux";

const ResultLr = () => {
  const personalDataObject = useSelector((state) => state.personalData.value);

  return (
    <ResultRequest
      content={
        <>
          <Row style={{ marginTop: "12%" }}>
            <Col
              style={{
                textAlign: "center",
                fontSize: "42px",
                color: "#fff",
              }}
            >
              {/* Gracias por la información<br />
                            suministrada */}
              {personalDataObject.firstName} gracias por preferirnos.
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center", color: "#fff" }}>
              <small
                style={{
                  fontSize: "25px",
                }}
              >
                Un representante te contactará
              </small>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default ResultLr;
