import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './react-datetime.css';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import RequestPd from './Components/Pages/RequestPd';
import RequestLa from './Components/Pages/RequestLa';
import RequestDo from './Components/Pages/RequestDo';
import ResultLr from './Components/Pages/ResultLr';
import WarningLr from './Components/Pages/WarningLr';
import Menu from './Components/Pages/Menu';
import NotFound from './Components/Templates/NotFound';
import MenuContactCenter from './Components/Pages/MenuContactCenter';
import LoanAlianza from './Components/Pages/LoanAlianza';
import LoanMadres from './Components/Pages/LoanMadres';
import LoanLinkQR from './Components/Pages/LoanLinkQR';
import MadresPage from './Views/Madres';
import PadresPage from './Views/Padres';
import PuetoPaMiBancoPage from './Views/PuetoPaMiBanco';
// import MenuQR from './Components/Pages/MenuQR';
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='*' element={<NotFound />}></Route>

        {/* <Route exact path='/link-qr' element={<MenuQR />}></Route> */}
        {/* <Route exact path='/alianza' element={<MenuAlianza />}></Route> */}
        <Route exact path='/alianza' element={<LoanAlianza />}></Route>
        <Route exact path='/pueto-pa-mi-banco' element={<PuetoPaMiBancoPage />}></Route>
        <Route exact path='/madres' element={<MadresPage />}></Route>
        <Route exact path='/padres' element={<PadresPage />}></Route>
        {/* <Route exact path='/madres' element={<LoanMadres />}></Route> */}
        <Route exact path='/link-qr' element={<LoanLinkQR />}></Route>
        <Route exact path='/contact-center' element={<MenuContactCenter />}></Route>
        <Route exact path='/loan' element={<RequestPd />}></Route>
        <Route exact path='/personal' element={<RequestPd />}></Route>
        <Route exact path='/save/account' element={<RequestPd productType={2} />}></Route>
        <Route exact path='/certificate' element={<RequestPd productType={7} />}></Route>
        <Route exact path='/labor' element={<RequestLa />}></Route>
        <Route exact path='/domiciled' element={<RequestDo />}></Route>
        <Route exact path='/request/result/success' element={<ResultLr />}></Route>
        <Route exact path='/warning/loan/request' element={<WarningLr />}></Route>
        <Route exact path='/' element={<Menu />}></Route>
        <Route exact path='/menu' element={<Menu />}></Route>
      </Routes>
    </Router>
  );
}
export default App;
