import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
const Check = (props) => {
    const { text, reference } = props;
    return <Form.Check ref={reference} type="checkbox" label={text} />
}
Check.propTypes = {
    text: PropTypes.any.isRequired,
    reference: PropTypes.any
}
export default Check;