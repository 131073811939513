import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import InputText from "../../Atoms/ReactBootstrapInputText";
import MarkInput from "../../Atoms/MarkInput";
const MarkInputGroup = (props) => {
    const { 
            colSize,
            inputTextValid,
            id,
            mask,
            maskChar,
            placeholder,
            value,
            onChange,
            refElement
         } = props;
    return (
        <Col md={colSize}>
            <Form.Group className="mb-4">
                <MarkInput                     
                    mask={mask}
                    maskChar={maskChar}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    refElement={refElement}
                />
                {
                    (
                        inputTextValid!==undefined&&
                        inputTextValid!==null&&
                        !inputTextValid.valid&&
                        id===inputTextValid.key
                    )?<InputText text={inputTextValid.text} className="text-danger" style={{marginLeft: '5px'}} />
                    :null
                }
            </Form.Group>        
        </Col>
    )
}
MarkInputGroup.propTypes = {
    colSize: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    ref: PropTypes.any,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    refElement: PropTypes.any
}
export default MarkInputGroup;