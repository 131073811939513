import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ReactBootstrapLoadingButton = (props) => {
    const {className} = props;
    return <Button variant="secondary" className={className}>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            /> Cargando...
           </Button>;
}
ReactBootstrapLoadingButton.propTypes = {
    className: PropTypes.string
}
export default ReactBootstrapLoadingButton;