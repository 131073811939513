import React from "react";
import Domiciled from "../Components/Organisms/Domiciled";
import CustomCard from "../Components/Templates/CustomCard";
import PropTypes from "prop-types";
import { Row, Col, Spinner } from "react-bootstrap";
const DomiciledView = (props) => {
    const { defaultData, loading } = props;
    return (
        <CustomCard
            text="Ya casi terminamos con tu solicitud"
            content={
                <>
                    {
                    loading?
                        <Row className="justify-content-center">
                            <Col md={2} className="mb-3">
                                <Spinner animation="grow" role="status" className="string-primary-color" style={{marginBottom: '5px'}} />
                                <span className="visually-hidden">Cargando información, por favor espere...</span>
                            </Col>
                        </Row>
                    :null
                }
                    <Domiciled defaultData={defaultData} />
                </>
            }
        />
    );
}
DomiciledView.propTypes = {
    defaultData: PropTypes.object
}
export default DomiciledView;