import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import ReactBootstrapButton from "../../Atoms/ReactBootstrapButton";
import { Form } from "react-bootstrap";
import FormGroup from "../../Molecules/ReactBootstrapFormGroupInput";
import FormGroupSelect from "../../Molecules/ReactBootstrapFormGroupSelect";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setValue } from "../../../features/counter/domiciledSlice";
import { setValue as laborAcademicSetValue } from "../../../features/counter/laborAcademicSlice";

import { paths } from "../../../Models/general.model";
import ReactBootstrapLoadingButton from "../../Atoms/ReactBootstrapLoadingButton";
import axios from "axios";
import ReactSelectGroup from "../../Molecules/ReactSelectGroup";
import { useNavigate } from "react-router-dom";
import FormGroupDate from "../../Molecules/ReactBootstrapFormGroupDate";
import Check from "../../Atoms/ReactBootstrapCheck";
import ReCAPTCHA from "react-google-recaptcha";
import { AxiosBackend_API, AxiosBackend_API_PB3 } from "../../../config/Axios";

import $ from 'jquery';
import { InputNumber } from "primereact/inputnumber";


const defaultValid = {
    key: '',
    text: '',
    valid: true
};
const Domiciled = (props) => {
    const { defaultData } = props,
        { token } = useSelector((state) => state.login.value),
        domiciledObject = useSelector((state) => state.domiciled.value),
        personalDataObject = useSelector((state) => state.personalData.value),
        generalDataObject = useSelector((state) => state.general.value),
        laborAcademicObject = useSelector((state) => state.laborAcademic.value),
        typeProduct = useSelector((state) => state.typeProduct.value),
        [reCAPTCHAValid, setReCAPTCHAValid] = useState(false),
        dispatch = useDispatch(),
        checkRef = useRef(),
        navigate = useNavigate(),
        [subProductos, setSubProductos] = useState([]),
        [idPersona, setIdPersona] = useState(0),
        [dataOrigenIngresos, setDataOrigenIngresos] = useState([]),
        [subGrupoActividades, setSubGrupoActividades] = useState([]),
        [profesiones, setProfesiones] = useState([]),
        [montoASolicitar, setMontoASolicitar] = useState(0),
        [plazo, setPlazo] = useState(0),
        [resValid, setResValid] = useState(defaultValid),
        [docFile, setDocFile] = useState(null),
        [loading, setLoading] = useState(false),
        [provincias, setProvincias] = useState([]),
        [selectedProvincia, setSelectedProvincia] = useState(0),
        [cities, setCities] = useState([]),
        [selectedCiudad, setSelectedCiudad] = useState(0),
        // [distritos, setDistritos] = useState([]),
        // [selectedDistrito, setSelectedDistrito] = useState(0),
        [sectores, setSectores] = useState([]),
        [selectedSectores, setSelectedSector] = useState(0),
        [sucursales, setSucursales] = useState([]),
        [selectedSucursales, setSelectedSucursales] = useState(0),
        [selectedSucursal, setSelectedSucursal] = useState(0),
        [oficiales, setOficiales] = useState([]),
        [mostrarOficiales, setMostrarOficiales] = useState(false),
        [selectedOficial, setSelectedOficial] = useState(0),
        [codigoDePersonal, setCodigoDePersonal] = useState('')


    const getProvincias = async () => {

        try {
            const response = await AxiosBackend_API.get('/provincias', {
                headers: { 'token': token }
            });

            // console.log( response.data )
            if (response && Array.isArray(response.data)) {
                const updatedProvincias = response.data.map(item => ({
                    id: item.id,
                    text: item.descripcion
                }));
                setProvincias(updatedProvincias);
                // console.log(updatedProvincias)
            } else {
                console.log('No se encontraron datos válidos en la respuesta.');
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    }

    const getCities = async () => {


        try {
            const response = await AxiosBackend_API.get(`/municipios/${selectedProvincia}`, {
                headers: { 'token': token }
            });

            // console.log(response.data)

            if (response && Array.isArray(response.data)) {
                const updatedProvincias = response.data.map(item => ({
                    id: item.id,
                    text: item.descripcion
                }));
                setCities(updatedProvincias);

            } else {
                console.log('No se encontraron datos válidos en la respuesta.');
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }

        // console.log(cities)


    }


    const getDataOrigenIngresos = async () => {


        const response = await AxiosBackend_API.get(`/origen-ingresos`, {
            headers: { 'token': token }
        })

        // console.log(response.data)
        setDataOrigenIngresos(response.data)

    }

    const getProfesiones = async () => {

        const response = await AxiosBackend_API.get(`/profesiones`, {
            headers: { 'token': token }
        })


        const updatedProfesiones = response.data.map(item => ({
            id: item.id_profesion,
            text: item.descripcion
        }));

        setProfesiones(updatedProfesiones)

    }

    const getSubGrupoActividades = async () => {

        const response = await AxiosBackend_API.get(`/subgrupo-actividades`, {
            headers: { 'token': token }
        })


        const updatedSubGrupoActividades = response.data.map(item => ({
            id: item.id,
            text: item.descripcion
        }));

        setSubGrupoActividades(updatedSubGrupoActividades)

    }


    const getSectores = async () => {


        if (selectedProvincia && selectedCiudad) {
            try {
                const response = await AxiosBackend_API.get(`/sectores/1/${selectedProvincia}/${selectedCiudad}`, {
                    headers: { 'token': token }
                });
                // console.log(response.data)

                if (response && Array.isArray(response.data)) {
                    const updatedSectores = response.data.map(item => ({
                        id: item.id_sector,
                        text: item.descripcion,
                        id_distrito_municipal: item.id_distrito_municipal
                    }));
                    setSectores(updatedSectores);
                    // console.log(updatedSectores)
                } else {
                    console.log('No se encontraron datos válidos en la respuesta.');
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        }

    }

    const getSucursales = async () => {

        if (selectedProvincia && selectedCiudad) {
            try {
                const response = await AxiosBackend_API.get(`/sucursales/${selectedProvincia}/${selectedCiudad}`, {
                    headers: { 'token': token }
                });
                // console.log(response.data)
                if (response && Array.isArray(response.data)) {
                    const updatedSucursales = response.data.map(item => ({
                        id: item.id_sucursal,
                        text: item.descripcion
                    }));
                    setSucursales(updatedSucursales);
                    // console.log(updatedSucursales)
                } else {
                    console.log('No se encontraron datos válidos en la respuesta.');
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        }

    }



    useEffect(() => {

        $('#sucursal .css-qc6sy-singleValue').text('Sucursal  mas cercana')

        getSucursales()
        getProfesiones()
        getSubGrupoActividades()

    }, [selectedSectores])

    useEffect(() => {




        $('#sector .css-qc6sy-singleValue').text('Sector que reside')
        $('#sucursal .css-qc6sy-singleValue').text('Sucursal  mas cercana')
        setSucursales(null)


        if (selectedCiudad) getSectores()

    }, [selectedCiudad])


    useEffect(() => {

        $('#ciudad .css-qc6sy-singleValue').text('Ciudad que reside')
        $('#sector .css-qc6sy-singleValue').text('Sector que reside')
        $('#sucursal .css-qc6sy-singleValue').text('Sucursal  mas cercana')

        setSucursales(null)
        setSectores(null)


        if (selectedProvincia) getCities()

    }, [selectedProvincia])

    useEffect(() => {

        getProvincias()
        getDataOrigenIngresos()

    }, [])


    useEffect(() => {
        const rValid = validation();
        setResValid(rValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domiciledObject]);

    const reCaptchaOnChange = (value) => {
        //console.log("value>>",value);

        if (value !== null && value !== undefined) {
            setReCAPTCHAValid(true);
        } else {
            setReCAPTCHAValid(false);
        }
    }


    const formatearFecha = (fechaOriginal) => {

        const partesFecha = fechaOriginal.split('/');

        const fechaFormateada = `${partesFecha[2]}-${partesFecha[0].padStart(2, '0')}-${partesFecha[1].padStart(2, '0')}`;

        return fechaFormateada
    }

    const save = async () => {


        if (personalDataObject.id_origen === 41 && !selectedOficial) {
            return Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe elegir un oficial',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })
        }

        // if (!laborAcademicObject.sourceIncome) return Swal.fire({
        //     icon: 'warning',
        //     title: 'Advertencia',
        //     text: 'Debe completar su origen de ingreso',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'Cancelar'
        // })
        // if (!laborAcademicObject.profession) return Swal.fire({
        //     icon: 'warning',
        //     title: 'Advertencia',
        //     text: 'Debe completar su profesion',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'Cancelar'
        // })
        // if (!laborAcademicObject.subGroupActivity) return Swal.fire({
        //     icon: 'warning',
        //     title: 'Advertencia',
        //     text: 'Debe completar su actividad',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'Cancelar'
        // })

        // if (!laborAcademicObject.income) return Swal.fire({
        //     icon: 'warning',
        //     title: 'Advertencia',
        //     text: 'Debe especificar su salario.',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'Cancelar'
        // })

        // if (!laborAcademicObject.startDate) return Swal.fire({
        //     icon: 'warning',
        //     title: 'Advertencia',
        //     text: 'Debe especificar el tiempo laborando.',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'Cancelar'
        // })

        if (!domiciledObject.sucursal) return Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'Debe seleccionar la sucursal.',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar'
        })

        if (!domiciledObject.calle) return Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'Debe especificar la calle',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar'
        })

        if (!domiciledObject.casa) return Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'Debe especificar la casa.',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar'
        })


        setLoading(true);

        // const rValid = validation();
        // if (!rValid.valid) {
        //     if (rValid.sweet) {
        //         Swal.fire({
        //             icon: 'warning',
        //             title: 'Advertencia',
        //             text: rValid.text,
        //             cancelButtonColor: '#d33',
        //             cancelButtonText: 'Cancelar'
        //         });
        //     }
        //     setResValid(rValid);
        //     return;
        // }
        // setResValid(rValid);
        const formData = new FormData();
        // if(docFile===null||docFile===undefined){
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Advertencia',
        //         text: "Debes seleccionar un archivo.",
        //         cancelButtonColor: '#d33',
        //         cancelButtonText: 'Cancelar'
        //     });
        //     return;
        // }
        // const file = await imageToBase64(docFile);
        // if (docFile !== null && docFile !== undefined) {
        //     formData.append('domFile', docFile, docFile.name);
        // }
        // formData.append('file',file);


        // formData.append('city', domiciledObject.city);
        // formData.append('livingPlace', domiciledObject.livingPlace);
        // formData.append('time', domiciledObject.time);
        // formData.append('dependents', domiciledObject.dependents);
        // formData.append('id', personalDataObject.idFormatted);
        // formData.append('request', personalDataObject.request_id);


        // formData.append('dirFilePath', generalDataObject.pathForFile);
        try {
            // console.log(`personalDataObject: ${JSON.stringify(personalDataObject)}`)
            // console.log(`domiciledObject: ${JSON.stringify(domiciledObject)}`)

            // return console.log(`generalDataObject: ${JSON.stringify(generalDataObject)}`)


            // return console.log( laborAcademicObject.income, laborAcademicObject.academicLevel )

            const dataEncargado = await AxiosBackend_API.get(`/encargadoBySucursal/${domiciledObject.sucursal}`, {
                headers: { 'token': token }
            })

            const encargado = dataEncargado.data[0]?.id_usuario
            const emailEncargado = dataEncargado.data[0]?.email
            const nombreEncargado = dataEncargado.data[0]?.nombre

            if (!encargado) {

                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: "Error al enviar la solicitud. Intente mas tarde.",
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                })

                return console.log('No hay encargado para esta sucursal')
            }

            let response = 0

            try {
                // response = await AxiosBackend_API_PB3.get(`/getDatosJCE2WEB/1/${personalDataObject.idFormatted}/${personalDataObject.email}/${personalDataObject.phone}/1`)

                // console.log(token)

                const email = personalDataObject.email ? personalDataObject.email : "default@example.com";

                // console.log(`/getDatosJCE2WEB/1/${personalDataObject.idFormatted}/${email}/${personalDataObject.phone}/1`)

                // return console.log( `/getDatosJCE2WEB/1/${personalDataObject.idFormatted}/${email}/${personalDataObject.phone}/1 `)

                response = await AxiosBackend_API_PB3.get(`/getDatosJCE2WEB/1/${personalDataObject.idFormatted}/${email}/${personalDataObject.phone}/1`, {
                    headers: {
                        'token': token
                    }
                });


                // return alert(response.data)

                setIdPersona(response.data)

            } catch (error) {

                // console.log(token)

                console.log(error.response.data.error)

                if (error.response.data.error === 'Token Inválido') {
                    return Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'Error al enviar la solicitud. Intente mas tarde.',
                        cancelButtonColor: '#d33',
                        // cancelButtonText: 'Cancelar'
                    })
                }
            }

            //console.log(response.data)

            // id_provincia, id_municipio, id_distrito_municipal, id_sector, id_zona

            const dataToApi = {
                // 'id_provincia': selectedProvincia,
                // 'id_municipio': selectedCiudad,
                // 'id_distrito_municipal': "soon...", 
                'id_sector': domiciledObject.city,
                // 'id_zona': 1,
                "id_persona": response.data,
                "cedula": personalDataObject.idFormatted,
                "telefono": personalDataObject.phone,
                "nombre_solicitante": personalDataObject.firstName + " " + personalDataObject.lastName,
                // "cedulaFormatted": personalDataObject.idFormatted,
                // "lastName": personalDataObject.lastName,
                // "phone": personalDataObject.phone,
                // "request_id": personalDataObject.request_id,
                // "id_sector": domiciledObject.city,
                // "sucursal_id": domiciledObject.sucursal,
                "id_sub_origen": laborAcademicObject.sourceIncome ? parseInt(laborAcademicObject.sourceIncome) : 999,
                // "startDate": laborAcademicObject.startDate,
                // "academicLevel": parseInt(laborAcademicObject.academicLevel),
                // "profession": laborAcademicObject.profession,
                // "applyingLabor": true,
                // "estado": true,
                "id_origen": parseInt(personalDataObject.id_origen), // tabla lst_origen, id 7 = WEB
                "id_sub_producto": personalDataObject.typeSubProducto,
                "monto": personalDataObject.montoASolicitar,
                "plazo": personalDataObject.plazo > 0 ? personalDataObject.plazo * 12 : 0,
                "ingresos_mensuales": parseInt(laborAcademicObject.income),
                "id_producto": typeProduct ? typeProduct : parseInt(3),
                "id_estado_general": 1,
                "usuario_registro": 1,
                "id_oficial": selectedOficial ? selectedOficial : encargado,
                "email_encargado": emailEncargado,
                "nombre_encargado": nombreEncargado,
                "id_sucursal": domiciledObject.sucursal,
                // "fecha_inicio_trabajo": await formatearFecha(laborAcademicObject.startDate),
                "fecha_inicio_trabajo": parseInt(laborAcademicObject.startDate),
                "calle": domiciledObject.calle,
                "casa": domiciledObject.casa,
                "id_sub_grupo_actividad": laborAcademicObject.subGroupActivity ? parseInt(laborAcademicObject.subGroupActivity) : 999,
                "id_profesion": laborAcademicObject.profession ? parseInt(laborAcademicObject.profession) : 999,
                "id_clase_origen": laborAcademicObject.id_clase_origen ? parseInt(laborAcademicObject.id_clase_origen) : 999,
                "codigo_empleado": codigoDePersonal ? codigoDePersonal : 0
            }

            const resource = await AxiosBackend_API.post('/oportunidades', dataToApi)
            const data = await resource.data;


            if (data.code === 409 && data.status === "ok") {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.message,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                })
            }


            if (resource.status === 404) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: resource.statusText,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                })
            }

            if (!resource.status === "201") {
                // console.log(data)
                setLoading(false);
                return Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: resource.statusText,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar'
                });
            }

            navigate('/request/result/success')

            // if (!resource.status === "success") {
            //     console.log(data)
            //     setLoading(false);
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'Advertencia',
            //         text: data.response,
            //         cancelButtonColor: '#d33',
            //         cancelButtonText: 'Cancelar'
            //     });
            // }

            // navigate('/request/result/success');

            // console.log(`personalDataObject: ${JSON.stringify(personalDataObject)}`)
            // console.log(`domiciledObject: ${JSON.stringify(domiciledObject)}`)

            // return console.log(`generalDataObject: ${JSON.stringify(generalDataObject)}`)

            //     data = await resource.data;
            // if (data.status) {
            //     //window.location.href = paths.path + 'request/result/success';
            //     navigate('/request/result/success');
            // } else {
            //     setLoading(false);
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'Advertencia',
            //         text: data.response,
            //         cancelButtonColor: '#d33',
            //         cancelButtonText: 'Cancelar'
            //     });
            // }
        } catch (error) {
            //console.error(error);
            setLoading(false);
        }


    }
    const validation = () => {
        if (parseInt(domiciledObject.city) < 1) {
            return { key: 'city', text: 'Debe seleccionar al menos una ciudad', valid: false };
        }
        if (parseInt(domiciledObject.livingPlace) < 1) {
            return { key: 'livingPlace', text: 'Debe seleccionar al menos un tipo de vivienda', valid: false };
        }
        //if(!validator.isLength(domiciledObject.time+'',{min:1,max:2}))||{
        if (isNaN(parseInt(domiciledObject.time))) {
            return { key: 'time', text: 'Debe ingresar el tiempo que tiene residiendo', valid: false };
        }
        //if(!validator.isLength(domiciledObject.dependents,{min:1,max:2})){
        if (isNaN(parseInt(domiciledObject.dependents))) {
            return { key: 'dependents', text: 'Debe ingresar la cantidad de dependientes', valid: false };
        }
        if (docFile !== null && docFile !== undefined) {
            if (parseInt(domiciledObject.documentType) < 1) {
                return { key: 'documentType', text: 'Debe seleccionar al menos un tipo de documento', valid: false };
            }
        }
        return { key: 'success', text: 'Todos los campos fueron validados', valid: true };
    }
    const handleDocumentFile = (event) => {
        setDocFile(event.target.files[0]);
    }

    const handleSector = (handleData) => {

        setSelectedSector(handleData.value)
        dispatch(setValue({ key: 'city', value: handleData.value }))

    }

    const handleChangeASolicitar = ({ target }) => {
        setMontoASolicitar(target.value)
        dispatch(setValue({ key: 'montoASolicitar', value: parseInt(target.value) }))
    }
    const handleChangePlazo = ({ target }) => {
        setPlazo(target.value)
        dispatch(setValue({ key: 'plazo', value: parseInt(target.value) }))
    }

    const getSubProductos = async () => {


        if (process.env.REACT_APP_ENV === 'produccion') {
            if (personalDataObject.id_origen === 15) setMostrarOficiales(true)
        } else {
            if (personalDataObject.id_origen === 41) setMostrarOficiales(true)
        }


        if (typeProduct) {

            const { data } = await AxiosBackend_API(`/sub-productos/${typeProduct}`, {
                headers: { 'token': token }
            })

            // console.log( resource.data )
            setSubProductos(data)
        }
    }

    useEffect(() => {
        getSubProductos()

    }, [])

    const handleChangeOrigenIngresos = (selectedValue) => {
        // Convertir selectedValue a número
        selectedValue = parseInt(selectedValue, 10);

        dispatch(laborAcademicSetValue({ key: "sourceIncome", value: selectedValue }))

        // Buscar el elemento en dataOrigenIngresos
        const selectedOption = dataOrigenIngresos.find(item => item.id === selectedValue);

        if (selectedOption) dispatch(laborAcademicSetValue({ key: "id_clase_origen", value: selectedOption.id_clase_origen }))

    }



    const handleChangeSucursal = (handleData) => {

        setSelectedSucursal(handleData.value)
        dispatch(setValue({ key: 'sucursal', value: handleData.value }))

    }


    const getOficialesBySucursal = async () => {

        const response = await AxiosBackend_API.get(`/getOficialesBySucursal/${selectedSucursal}`)

        const updatedOficiales = response.data.map(item => ({
            id: item.id_usuario,
            text: item.nombre
        }))

        $('#oficiales .css-qc6sy-singleValue').text('Oficiales')

        setOficiales(updatedOficiales)

    }

    useEffect(() => {

        getOficialesBySucursal()

    }, [selectedSucursal])


    const handleCodigoPersonal = ({target}) => {

        const newValue = target.value;

        if (newValue.length > 4) {
            newValue = newValue.slice(0, 4);
        }

        if (/^\d*$/.test(newValue)) {
            setCodigoDePersonal(target.value)
            // dispatch(setValue({ key: 'plazo', value: parseInt(target.value) }))
        }

    }

    return (
        <div >

            {/* SE COLOCO NUEVO */}
            {/* <Row className="mt-4">
                <Col className="mb-2" style={{ marginTop: "-20px" }}>
                    <h5>Datos Laboral</h5>
                </Col>
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: "-1px" }}>
                <Col md={12}>

                    <select
                        name="origen_ingresos"
                        className="form-select"
                        onChange={(e) => handleChangeOrigenIngresos(e.target.value)}
                    >
                        <option value="0" selected>--Seleccione su fuente de ingreso--</option>
                        {dataOrigenIngresos.map(item => (
                            <option value={item.id} key={item.id}>{item.text}</option>
                        ))}
                    </select>


                 
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: "15px" }}>
                <Col md={12}>
                    <select
                        name="profesiones"
                        className="form-select"
                        onChange={(e) =>
                            dispatch(laborAcademicSetValue({ key: "profession", value: e.target.value }))
                        }
                    >
                        <option value="0" selected>--Seleccione su profesion--</option>
                        {profesiones.map(item => (
                            <option value={item.id} key={item.id}>{item.text}</option>
                        ))}
                    </select>
                </Col>
              

            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: "15px" }}>

                <Col md={12}>
                    <select
                        name="subgrupoActividad"
                        className="form-select"
                        onChange={(e) =>
                            dispatch(laborAcademicSetValue({ key: "subGroupActivity", value: e.target.value }))
                        }
                    >
                        <option value="0" selected>--Seleccione su actividad--</option>
                        {subGrupoActividades.map(item => (
                            <option value={item.id} key={item.id}>{item.text}</option>
                        ))}
                    </select>
                </Col>

            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: "15px" }}>
              
                <InputNumber
                    // value={laborAcademicObject.income}
                    // onValueChange={(e) => handleChangeASolicitar(e)}
                    onValueChange={(e) => dispatch(laborAcademicSetValue({ key: "income", value: e.target.value }))}
                    name="montoASolicitar"
                    minFractionDigits={0} maxFractionDigits={5}
                    maxLength={10}
                    placeholder="Ingresos Mensuales"
                    min={1}
                />
            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: "15px" }}>
              
                <InputNumber
                    //    value={laborAcademicObject.income}
                    // onValueChange={(e) => handleChangeASolicitar(e)}
                    onValueChange={(e) => dispatch(laborAcademicSetValue({ key: 'startDate', value: parseInt(e.value) }))}
                    // name="montoASolicitar"
                    // maxLength={10}
                    placeholder="Tiempo en la Actividad (Meses)"
                    mode="decimal"
                    min={1}
                />

            </Row> */}


            <Row>
                <Col className="mb-2">
                    <h3>Datos Domiciliado</h3>
                </Col>
            </Row>
            <Row className="justify-content-md-center">

                <ReactSelectGroup
                    colSize={12}
                    options={provincias}
                    defaultValue={{ value: 0, label: 'Provincia que reside' }}
                    onChange={(handleData) => setSelectedProvincia(handleData.value)}
                    inputTextValid={resValid}
                    id="provincia"

                />




                <div style={{ marginTop: "-8px" }}>
                    <ReactSelectGroup
                        colSize={12}
                        // options={defaultData.cities}
                        options={cities}
                        defaultValue={{ value: 0, label: 'Ciudad que reside' }}
                        onChange={(handleData) => setSelectedCiudad(handleData.value)}
                        inputTextValid={resValid}
                        id="ciudad"

                    />
                </div>

                {/* <ReactSelectGroup
                    colSize={12}
                    // options={defaultData.cities}
                    options={distritos}
                    defaultValue={{ value: 0, label: 'Distrito que reside' }}
                    onChange={(handleData) => setSelectedDistrito(handleData.value)}
                    // onChange={(handleData) => dispatch(setValue({ key: 'city', value: handleData.value }))}
                    inputTextValid={resValid}
                    id="distrito"
                /> */}
                <div style={{ marginTop: "-8px" }}>


                    <ReactSelectGroup
                        colSize={12}
                        options={sectores}
                        // defaultValue={{ value: 0, label: 'Sector que reside' }}
                        defaultValue={{ value: 0, label: 'Sector que reside' }}
                        onChange={(handleData) => handleSector(handleData)}
                        inputTextValid={resValid}
                        id="sector"
                    />

                </div>
            </Row>
            <div style={{ marginTop: "-8px", display: 'flex', justifyContent: 'space-between' }}>
                <FormGroup
                    colSize={6}
                    type="text"
                    placeholder="Calle que reside"
                    style={{ marginRight: '8px' }} // Añade los estilos directamente aquí
                    onChange={(e) => dispatch(setValue({ key: 'calle', value: e.target.value }))}
                ></FormGroup>
                <FormGroup
                    colSize={6}
                    type="text"
                    placeholder="Casa que reside"
                    onChange={(e) => dispatch(setValue({ key: 'casa', value: e.target.value }))}
                ></FormGroup>
            </div>


            <div style={{ marginTop: "-8px" }}>

                <ReactSelectGroup
                    colSize={12}
                    // options={defaultData.cities}
                    options={sucursales}
                    defaultValue={{ value: 0, label: 'Sucursal mas cercana' }}
                    onChange={(handleData) => handleChangeSucursal(handleData)}
                    // onChange={(handleData) => setSelectedSucursales(handleData.value)}
                    inputTextValid={resValid}
                    id="sucursal"
                />

            </div>

            {
                mostrarOficiales ?
                    <div style={{ marginTop: "-8px" }}>
                        <ReactSelectGroup
                            colSize={12}
                            // options={defaultData.cities}
                            options={oficiales}
                            defaultValue={{ value: 0, label: 'Oficiales' }}
                            // onChange={(handleData) => handleChangeSucursal(handleData)}
                            onChange={(handleData) => setSelectedOficial(handleData.value)}
                            inputTextValid={resValid}
                            id="oficiales"
                        />
                    </div>
                    : null
            }

            {
                personalDataObject.id_origen === parseInt(process.env.REACT_APP_PUETOPAMIBANCO_ID) ?
                    <Row className="" style={{ marginTop: '-1px' }}>
                        <FormGroup

                            colSize={5}
                            type="text"
                            placeholder="Codigo de Personal"
                            // value={personalDataObject.lastName}
                            // onChange={(e) => dispatch(setValue({ key: 'lastName', value: e.target.value }))}
                            // inputTextValid={resValid}
                            onChange={(e) => handleCodigoPersonal(e)}
                            value={ codigoDePersonal }
                            className="my-2"
                        />
                    </Row>
                    : null
            }


            <Row className="justify-content-md-center">
                <Col md={12} className="mb-4">
                    {
                        loading ?
                            <ReactBootstrapLoadingButton className="long-button bg-primary-color" />
                            : <ReactBootstrapButton
                                text="ENVIAR"
                                variant="primary"
                                className="long-button bg-primary-color "
                                onClick={() => save()}
                            />
                    }
                </Col>
            </Row>

        </div>
    );
}
Domiciled.propTypes = {
    defaultData: PropTypes.object
}
export default Domiciled;