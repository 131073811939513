import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Image/*, Alert*/ } from "react-bootstrap";
import DomiciledView from "../../../Views/domiciledView";
import { paths } from "../../../Models/general.model";
import axios from "axios";
import { useSelector } from "react-redux";
import LoadingLr from "../LoadingLr";
const RequestDo = () => {
    const [defaultData, setDefaultData] = useState({}),
        [loading, setLoading] = useState(false),
        [laborNotCompleted, setLaborDataNotCompleted] = useState(true),
        laborAcademicObject = useSelector((state) => state.laborAcademic.value),
        personalDataObject = useSelector((state) => state.personalData.value);

    useEffect(() => {
        if (laborAcademicObject !== undefined && laborAcademicObject !== null && !laborAcademicObject.applyingLabor) {
            setTimeout(() => {
                window.location.href = paths.path + 'warning/loan/request';
            }, 1000);
            return;
        } else {
            setLaborDataNotCompleted(false);
        }
        getGeneralData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [laborAcademicObject]);
    const getGeneralData = async () => {

        /*
        setLoading(true);
        try{
            const resource = await axios.get(paths.generalData),
            data = await resource.data;
            setLoading(false);
            setDefaultData(data);
        } catch(error){
            setLoading(false);
        console.error(error);
    }
    */
    }

    const fnLink = () => {

        console.log(personalDataObject.id_origen)

        console.log(process.env.REACT_APP_LINK_QR_ID)

        if (parseInt(personalDataObject.id_origen) === parseInt(process.env.REACT_APP_LINK_QR_ID)) return '/#/link-qr'

        if (personalDataObject.id_origen === 999 || personalDataObject.id_origen === 3 || personalDataObject.id_origen === 5) return '/#/contact-center'

        if (personalDataObject.id_origen === 6) return '/#/alianza'


        return '/'

    }

    return (
        <Container fluid>
            {
                !laborNotCompleted ?
                    <Row>
                        <Col md={12} lg={6}>
                            <Navbar.Brand href={fnLink()}><Image src="/bgLogo.png" style={{ width: "100%" }} /></Navbar.Brand>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Image src="/LOGO-NEW.png" className="sub-img" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6} className="right-content-side">
                            <Row className="justify-content-md-center sub-content-side">
                                <Col md={9} style={{ padding: "2%" }}>
                                    <DomiciledView defaultData={defaultData} loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : <LoadingLr />
            }
        </Container>
    );
}
export default RequestDo;