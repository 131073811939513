import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
const Select = (props) => {
    const {
        options,
        defaultValue,
        onChange,
        disabled = false
    } = props;
    return <Form.Select value={defaultValue} onChange={onChange} disabled={disabled}>
            {
                (options)?
                    options.map((elm, idx)=>{
                        return <option key={'op-'+idx} value={elm.id}>{elm.text}</option>
                    })
                : null
            }                
           </Form.Select>;
}
Select.propTypes = {
    options: PropTypes.array,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func
}
export default Select;