import React from "react";
import { Col, Container, Navbar, Row, Image, Button } from "react-bootstrap";

const NotFound = () => {
    return (
        <Container>
            <Row>
                <Col md={12} lg={6}>
                    <Navbar.Brand href="/"><Image src="/bgLogo.png" style={{width: "100%"}} /></Navbar.Brand>
                </Col>
            </Row>
            <Row style={{marginTop: '10%'}}>
                <Col style={
                    {
                        textAlign: 'center',
                        fontSize: '92px',
                        color: '#fff'
                    }
                }>
                    404
                </Col>
            </Row>
            <Row>
                <Col style={
                    {
                        textAlign: 'center',
                        fontSize: '32px',
                        color: '#fff'
                    }
                }>
                    PÁGINA NO ENCONTRADA
                </Col>
            </Row>
            <Row style={{marginTop: '2%'}}>
                <Col style={{textAlign: 'center',color: '#fff'}}>
                    <Button className="menu-options-orange border-orange" onClick={()=>window.location.href='/'}>VOLVER A SOLICITAR</Button>
                    {/* <small>Un representante le contactará</small> */}
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound;