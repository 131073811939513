import React from "react";
import ResultRequest from "../../Templates/ResultRequest";
import { Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { paths } from "../../../Models/general.model";
const WarningLr = () => {
    return (
        <ResultRequest 
            content={
                <>
                    {/* <Row style={{marginTop: '12%'}}>
                        <Col style={
                            {
                                textAlign: 'center',
                                fontSize: '32px',
                                color: '#fff'
                            }
                        }>
                            Gracias por la información<br />
                            suministrada
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'center',color: '#fff'}}><small>Un representante le contactará</small></Col>
                    </Row> */}
                    <Row style={{marginTop: '10%'}}>
                        <Col>
                            <Alert variant="warning">
                                <Alert.Heading><FontAwesomeIcon icon={faTriangleExclamation} /> Eres importante para nosotros.</Alert.Heading>
                                <p>
                                    Nos hemos percatado de un cambio en el manejo de la información hasta este punto, 
                                    por esto informamos que no será posible continuar completando las informaciones de su solicitud, 
                                    no obstante puede volver a completar sus datos y culminar con la misma. Pedimos disculpas por el inconveniente que esto podría ocasionar.
                                </p>
                                <hr />
                                <p className="mb-0" style={{fontWeight: 'bold'}}>
                                    <a href={paths.path}>Volver a solicitud de préstamo</a>
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                </>
            }
        />
    )
}

export default WarningLr;