import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Image, /*Alert*/ } from "react-bootstrap";
import LaborAcademicsView from "../../../Views/laborAcademicsView";
import { paths } from "../../../Models/general.model";
import { useSelector } from "react-redux";
import axios from "axios";
import LoadingLr from "../LoadingLr";
import { AxiosBackend_API } from "../../../config/Axios";
//import { useNavigate } from "react-router-dom";
const RequestLa = () => {
    const [defaultData, setDefaultData] = useState({}),
        [loading, setLoading] = useState(false),
        [personalDataNotCompleted, setPersonalDataNotCompleted] = useState(true),
        personalDataObject = useSelector((state) => state.personalData.value)/*,
        navigate = useNavigate()*/;
    useEffect(() => {
        if (personalDataObject !== undefined && personalDataObject !== null && !personalDataObject.applying) {
            setTimeout(() => {
                window.location.href = paths.path + 'warning/loan/request';
            }, 1000);
            return;
        } else {
            setPersonalDataNotCompleted(false);
        }
        getGeneralData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalDataObject]);
    const getGeneralData = async () => {
        setLoading(true);
        try {
            // const resource = await axios.get(paths.generalData),
            //     data = await resource.data;
            const resource = await AxiosBackend_API.get('/laborData'),
                data = await resource.data;

           // console.log(data)
            setDefaultData(data);
            setLoading(false);
        } catch (error) {
            //console.error(error);
            setLoading(false);
        }
    }
    return (
        <Container fluid>
            {
                !personalDataNotCompleted ?
                    <Row>
                        <Col md={12} lg={6}>
                            <Navbar.Brand href="/"><Image src="/bgLogo.png" style={{ width: "100%" }} /></Navbar.Brand>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Image src="/LOGO-NEW.png" className="sub-img" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6} className="right-content-side">
                            <Row className="justify-content-md-center sub-content-side">
                                <Col md={9} style={{ padding: "2%" }}>
                                    <LaborAcademicsView defaultData={defaultData} loading={loading} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : <LoadingLr />
            }
        </Container>
    );
}
export default RequestLa;