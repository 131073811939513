import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Datetime from 'react-datetime';
import InputText from "../../Atoms/ReactBootstrapInputText";
const FormGroupDate = (props) => {
    const { 
        colSize, 
        format, 
        inputProps,
        onChange,
        inputTextValid,
        id
    } = props;
    return (
        <Col md={colSize}>
            <Form.Group className="mb-4">
                <Datetime 
                    dateFormat={format} 
                    timeFormat={false} 
                    inputProps={inputProps} 
                    onChange={onChange} 
                    closeOnSelect={true}
                    locale="es" />
                {                    
                    (
                        inputTextValid!==undefined&&
                        inputTextValid!==null&&
                        !inputTextValid.valid&&
                        id===inputTextValid.key
                    )?<InputText text={inputTextValid.text} className="text-danger" style={{marginLeft: '5px'}} />
                    :null
                }
            </Form.Group>
        </Col>
    )
}
FormGroupDate.propTypes = {
    colSize: PropTypes.number.isRequired,
    format: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    onChange: PropTypes.func
}
export default FormGroupDate;