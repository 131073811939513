import { configureStore } from "@reduxjs/toolkit";
import personalDataSlice from "../features/counter/personalDataSlice";
import laborAcademicSlice from "../features/counter/laborAcademicSlice";
import domiciledSlice from "../features/counter/domiciledSlice";
import generalSlice from "../features/counter/generalSlice";
import typeProductSlice from "../features/counter/typeProductSlice";
import typeSubProductSlice from "../features/counter/typeSubProductSlice";
import loginSlice from "../features/counter/loginSlice";

export default configureStore({
  reducer: {
    personalData: personalDataSlice,
    laborAcademic: laborAcademicSlice,
    domiciled: domiciledSlice,
    general: generalSlice,
    typeProduct: typeProductSlice,
    typeSubProduct: typeSubProductSlice,
    login: loginSlice,
  },
});
