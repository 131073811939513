import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ReactBootstrapButton = (props) => {
    const {text, variant, className, onClick} = props;
    return <Button variant={variant} className={className} onClick={onClick}>{text}</Button>;
}
ReactBootstrapButton.propTypes = {
    text: PropTypes.any.isRequired,
    variant: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
}
export default ReactBootstrapButton;