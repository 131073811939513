import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Image } from "react-bootstrap";
import PersonalInfoView from "../../../Views/personalInfoView";
import { paths } from "../../../Models/general.model";
import axios from "axios";
import PropTypes from 'prop-types';
const RequestPd = (props) => {
    const
        { productType } = props,
        [defaultData, setDefaultData] = useState({}),
        [loading, setLoading] = useState(false);

    /*
useEffect(() =>
    getGeneralData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
    */
    const getGeneralData = async () => {
        setLoading(true);
        try {

            // const resource = await axios.get(paths.generalData),
            //     data = await resource.data;
            // data['productType'] = productType;
            setDefaultData({});

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }


    }
    return (
        <Container fluid>
            <Row>
                <Col md={12} lg={6}>
                    <Navbar.Brand href="/#/link-qr"><Image src="/bgLogo.png" style={{ width: "100%" }} /></Navbar.Brand>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Image src="/LOGO-NEW.png" className="sub-img" />
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6} className="right-content-side">
                    <Row className="justify-content-md-center sub-content-side">
                        <Col md={9} style={{ padding: "2%" }}>
                            <PersonalInfoView defaultData={defaultData} origen={process.env.REACT_APP_LINK_QR_ID} loading={loading} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
RequestPd.propTypes = {
    productType: PropTypes.number
}
export default RequestPd;