import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        value: {
            token: ''
        }
    },
    reducers: {
        setValue: (state, action) => {
            state.value = {
                ...state.value,
                [action.payload.key]: action.payload.value
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { setValue } = loginSlice.actions

export default loginSlice.reducer